const module = [
  {
    id: '020d16dd-2ed1-4570-b1a0-2c3c232ab15f',
    slug: 'how-to-use-linked-in',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: '020d16dd-2ed1-4570-b1a0-2c3c232ab15f|10c10b32-fca4-497c-8694-e9f3e81fab20',
    template: 'pdf-template',
    title: 'How To Use LinkedIn',
    description:
      'In this guide it will explain how to get the best out of LinkedIn as part of your content and engagement strategy.',
    completedDescription: 'TODO',
    supplier: 'Ignition NBS',
    categoryId: '10c10b32-fca4-497c-8694-e9f3e81fab20',
    questionnaireId: '',
    contents: {
      id: 'c1af2b12-fa83-4e13-a6b1-beebc9f2df74',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        "In this guide you’ll  understand why LinkedIn is an essential component for building and effective and compelling content strategy." +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>Differences in Company pages and Personal Profiles</li>' +
        '<li>5 steps to raising awareness through LinkedIn</li>' +
        '<li>How to encourage employees to use LinkedIn positively</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'document',
          subType: 'pdf',
          title: 'How To Use LinkedIn',
          button: '',
          fileSize: 0,
          url: 'how-to-use-linked-in.pdf',
          caption: {
            title: '',
            description:
              ''
          }
        },
        {
          type: 'file',
          subType: 'pdf',
          title: 'How To Use LinkedIn',
          button: 'Download File',
          fileSize: '225332',
          url: 'how-to-use-linked-in.pdf',
          caption: {
            title: '',
            description: ''
          },
          axaUrl: ''
        }
      ]
    },
    progress: {
      value: 1,
      maximum: 1
    },
    statistics: {
      level: 0,
      timeEstimate: '30'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['marketing','sales','skills']
  },
];

export const HowToUseLinkedIn = {
  module
};