const module = [
  {
    id: '1aac2a43-d020-4d2d-b561-c412758144af',
    slug: 'how-to-vlog',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: '1aac2a43-d020-4d2d-b561-c412758144af|10c10b32-fca4-497c-8694-e9f3e81fab20',
    template: 'pdf-template',
    title: 'How To Vlog',
    description:
      'Now more than ever brokers need to find new ways to communicate with both their employees and clients.',
    completedDescription: 'TODO',
    supplier: 'Ignition NBS',
    categoryId: '10c10b32-fca4-497c-8694-e9f3e81fab20',
    questionnaireId: '',
    contents: {
      id: 'c1af2b12-fa83-4e13-a6b1-beebc9f2df74',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'Now more than ever brokers need to find new ways to communicate with both their employees and clients. Vlogging is a great way to engage with your audience and is a great alternative to both the written word and face-toface presentations.',
      heroImage: false,
      media: [
        {
          type: 'document',
          subType: 'pdf',
          title: 'How To Vlog',
          button: '',
          fileSize: 0,
          url: 'how-to-vlog.pdf',
          caption: {
            title: '',
            description:
              ''
          }
        },
        {
          type: 'file',
          subType: 'pdf',
          title: 'How To Vlog',
          button: 'Download File',
          fileSize: '192048',
          url: 'how-to-vlog.pdf',
          caption: {
            title: '',
            description: ''
          },
          axaUrl: ''
        }
      ]
    },
    progress: {
      value: 1,
      maximum: 1
    },
    statistics: {
      level: 0,
      timeEstimate: '30'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['marketing','skills']
  },
];

export const HowToVlog = {
  module
};