const module = [
  {
    id: 'f45fc4d9-a82c-4fce-aa97-e0ab831bc5cf',
    slug: 'how-to-perform-a-website-health-check',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: 'f45fc4d9-a82c-4fce-aa97-e0ab831bc5cf|10c10b32-fca4-497c-8694-e9f3e81fab20',
    template: 'pdf-template',
    title: 'How To Perform A Website Health Check',
    description:
      'Learn to look beyond the aesthetics and understand how effective your website is',
    completedDescription: 'TODO',
    supplier: 'Ignition NBS',
    categoryId: '10c10b32-fca4-497c-8694-e9f3e81fab20',
    questionnaireId: '',
    contents: {
      id: 'c1af2b12-fa83-4e13-a6b1-beebc9f2df74',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'You need to look beyond the aesthetics to understand how effective your website is. These simple checks will help all insurance brokers understand how easy it is for prospective customers to access and use their website',
      heroImage: false,
      media: [
        {
          type: 'document',
          subType: 'pdf',
          title: 'How To Perform A Website Health Check',
          button: '',
          fileSize: 0,
          url: 'how-to-perform-a-website-health-check.pdf',
          caption: {
            title: '',
            description:
              ''
          }
        },
        {
          type: 'file',
          subType: 'pdf',
          title: 'How To Perform A Website Health Check',
          button: 'Download File',
          fileSize: '413586',
          url: 'how-to-perform-a-website-health-check.pdf',
          caption: {
            title: '',
            description: ''
          },
          axaUrl: ''
        }
      ]
    },
    progress: {
      value: 1,
      maximum: 1
    },
    statistics: {
      level: 0,
      timeEstimate: '30'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['marketing']
  },
];

export const HowToPerformAWebsiteHealthCheck = {
  module
};