const module = [
  {
    id: 'f3a109d9-ffc2-4dd2-8b97-291f8b0acd6c',
    slug: 'navigating-the-new-normal',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: 'f3a109d9-ffc2-4dd2-8b97-291f8b0acd6c|d1c90dbc-6e2e-4e77-90c3-319a5bc220a6',
    template: 'pdf-template',
    title: 'Navigating The New Normal',
    description:
      'Read our article to help your team navigate through change and uncertainty.',
    completedDescription: 'TODO',
    supplier: 'Ignition NBS',
    categoryId: 'd1c90dbc-6e2e-4e77-90c3-319a5bc220a6',
    questionnaireId: '',
    contents: {
      id: '14c1540a-b84d-4c01-9145-d2d8ff7a8e76',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'As a people manager, how do you help your team navigate through change and uncertainty? This article will provide you with tips and guidance, including how to maintain a sense of control and communicate through uncertainty.' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>The risk of business as usual</li>' +
        '<li>Making time and building a culture of feedback</li>' +
        '<li>Managing upwards and communicating through uncertainty</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'document',
          subType: 'pdf',
          title: 'Navigating The New Normal',
          button: '',
          fileSize: 0,
          url: 'navigating-the-new-normal.pdf',
          caption: {
            title: '',
            description:
              ''
          }
        },
        {
          type: 'file',
          subType: 'pdf',
          title: 'Navigating The New Normal',
          button: 'Download File',
          fileSize: '106496',
          url: 'navigating-the-new-normal.pdf',
          caption: {
            title: '',
            description: ''
          },
          axaUrl: ''
        }
      ]
    },
    progress: {
      value: 1,
      maximum: 1
    },
    statistics: {
      level: 0,
      timeEstimate: '30'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['management','leadership','wellbeing']
  },
];

export const NavigatingTheNewNormal = {
  module
};