const module = [
  {
    id: '021e5e9c-1731-465d-95f0-6c1ef6c41625',
    slug: 'cultivating-diversity-inclusion-and-belonging-at-work',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: false,
    concatenatedID: '021e5e9c-1731-465d-95f0-6c1ef6c41625|f509e87d-97bb-411d-a145-a0dda3f0a5d4',
    template: 'zing365-iframed-template',
    title: 'Cultivating diversity, inclusion & belonging at work',
    description:
      'The benefits of diversity in the workplace, impact of inclusion & strategies for creating inclusive diverse work environments.',
    completedDescription: 'TODO',
    supplier: 'Zing 365',
    categoryId: 'f509e87d-97bb-411d-a145-a0dda3f0a5d4',
    questionnaireId: '',
    contents: {
      id: '70f1f4f9-0c3b-460d-a2d3-df7af5515b51',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'In this course we explore the benefits of diversity in the workplace, the impact of inclusion on  employee engagement and productivity and discuss practical strategies for creating a more inclusive and diverse work environment in the context of the insurance industry.' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>Understand and be able to define the core concepts of diversity, inclusion, and equity</li>' +
        '<li>Recognise the benefits of a more diverse and inclusive workforce.</li>' +
        '<li>Gain practical strategies and tools for overcoming barriers and how to foster a culture of belonging.</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'iframe',
          subType: 'module',
          title: '',
          button: '',
          fileSize: 0,
          url: '/modules/021e5e9c-1731-465d-95f0-6c1ef6c41625/media/cultivating-diversity-inclusion-and-belonging-at-work/index.html',
          caption: {
            title: '',
            description: '',
          },
        },
      ],
    },
    progress: {
      value: 1,
      maximum: 1,
    },
    statistics: {
      level: 1,
      timeEstimate: '30',
    },
    status: {
      state: 'not-started',
      completedDate: '',
    },
    tags: ['intermediate', 'esg', 'inclusion', 'management'],
  },
];

export const CultivatingDiversityInclusionAndBelongingAtWork = {
  module,
};
