const module = [
  {
    id: 'bed25c5d-f4e3-42ce-b509-64cd88fd58fb',
    slug: 'induction-planning-template',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: 'bed25c5d-f4e3-42ce-b509-64cd88fd58fb|d1c90dbc-6e2e-4e77-90c3-319a5bc220a6',
    template: 'pdf-template',
    title: 'Induction Planning Template',
    description:
      'Use our basic induction planning template to help you save time and consider key elements of the onboarding process.',
    completedDescription: 'TODO',
    supplier: 'Ignition NBS',
    categoryId: 'd1c90dbc-6e2e-4e77-90c3-319a5bc220a6',
    questionnaireId: '',
    contents: {
      id: 'a5475ed8-6cd2-404c-87f9-c486c5a80b82',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'New joiner starting soon? Use our basic induction planning template to help you save time and consider key elements of the onboarding process, such as on-the-job training, meetings with stakeholders and assigning a buddy and mentor.',
      heroImage: false,
      media: [
        {
          type: 'document',
          subType: 'pdf',
          title: 'Induction Planning Template',
          button: '',
          fileSize: 0,
          url: 'induction-planning-template.pdf',
          caption: {
            title: '',
            description:
              ''
          }
        },
        {
          type: 'file',
          subType: 'pdf',
          title: 'Induction Planning Template',
          button: 'Download File',
          fileSize: '551169',
          url: 'induction-planning-template.pdf',
          caption: {
            title: '',
            description: ''
          },
          axaUrl: ''
        }
      ]
    },
    progress: {
      value: 1,
      maximum: 1
    },
    statistics: {
      level: 0,
      timeEstimate: '30'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['management','leadership']
  },
];

export const InductionPlanningTemplate = {
  module
};