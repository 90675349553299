let tenant = {
  id: '59b9aef5-15cd-4978-9b45-0964261181e3',
  name: 'Broker Elevate',
  slug: 'brokerelevate-co-uk',
  apiSlug: 'brokerelevate-co-uk',
  websiteUrl: 'https://brokerelevate.co.uk',
  copyrightYear: '2025',
  googleAnalytics: {
    id: 'G-EPYJJKC33Q',
    appName: 'brokerelevate.co.uk',
  },
  showNewsletterSignup: false,
  showDebug: false,
  auth: {
    accessCodes: [],
    enableFacebook: false,
    enableGoogle: false,
    enableLinkedIn: false,
    relatedTenants: 'Brokerwise',
    requiresAccessCode: false,
  },
  filePath: '',
  curriculum: {
    courses: [
      {
        id: '7e9e8508-7258-4b00-a543-074414cb5f36',
        slug: 'financial-wellbeing-the-impact-on-mental-health',
        modules: [
          {
            id: '966058dd-cf46-4546-a6a3-4b1408e93412',
            slug: 'financial-wellbeing-the-impact-on-mental-health',
            tags: {
              additions: ['new'],
            },
          },
        ],
      },
      {
        id: '3187c558-5836-4721-a158-776c11b266ee',
        slug: 'addressing-the-needs-of-existing-clients-for-win-win-results',
        modules: [
          {
            id: '46d2deec-deb2-4b52-af9d-07b127577d7f',
            slug: 'benefits-of-meeting-existing-clients-needs',
            tags: {
              additions: ['new'],
            },
          },
          {
            id: 'a8b83a3a-f74b-4a7a-949d-8e7243ec64a2',
            slug: 'key-skills-and-approaches-for-addressing-clients-needs',
            tags: {
              additions: ['new'],
            },
          },
          {
            id: 'f879eee4-9990-4ed6-999b-9dbeb30607ca',
            slug: ' typical-challenges-you-need-to-overcome-to-help-meet-existing-clients-needs',
            tags: {
              additions: ['new'],
            },
          },
        ],
      },
      {
        id: 'd139596c-fd9b-4651-815a-6de33d2601ab',
        slug: 'axa-modern-building-techniques',
        modules: [
          {
            id: 'be8d85f2-4054-4f56-92fb-0256bc9ce996',
            slug: 'modern-building-techniques',
          },
        ],
      },
      {
        id: 'efba1b84-412d-46e7-bea0-05adf97e06af',
        slug: 'axa-lithium-ion-batteries',
        modules: [
          {
            id: '04f568ff-a6e2-494c-8640-7bf1ef6f378b',
            slug: 'lithium-ion-batteries',
          },
        ],
      },
      {
        id: '3038339a-ac33-42da-bf74-7dd66270aed5',
        slug: 'axa-mind-health-and-wellbeing-at-work',
        modules: [
          {
            id: '096bb77e-f692-4cbc-ac2d-625e50fe93a6',
            slug: 'mind-health-and-wellbeing-at-work',
          },
        ],
      },
      {
        id: 'f698ff3b-eb6f-4635-8f7e-a81c6d3f69ae',
        slug: 'axa-mlp-training',
        modules: [
          {
            id: '6073f6b6-f786-420b-8bc1-ba12b415b8e3',
            slug: 'mlp-introduction-to-mlp',
            questionnaireOverride: {
              passScore: 2,
              mix: {
                easy: 0,
                medium: 2,
                hard: 0,
              },
            },
          },
          // {
          //   id: '2f942795-13c7-46c9-965e-4760885a20bf',
          //   slug: 'mlp-directors-officers-cover',
          //   tags: {
          //     additions: ['new']
          //   },
          //   questionnaireOverride: {
          //     passScore: 2,
          //     mix: {
          //       easy: 0,
          //       medium: 2,
          //       hard: 0
          //     }
          //   }
          // },
          {
            id: 'b4cb83af-8c23-42d5-a4aa-0d750f5dc6b6',
            slug: 'mlp-company-legal-liability-cover',
            questionnaireOverride: {
              passScore: 2,
              mix: {
                easy: 0,
                medium: 2,
                hard: 0,
              },
            },
          },
          {
            id: '18c81c71-6d3e-408e-9796-9261a492c0b9',
            slug: 'mlp-employment-practices-liability-cover',
            questionnaireOverride: {
              passScore: 2,
              mix: {
                easy: 0,
                medium: 2,
                hard: 0,
              },
            },
          },
          {
            id: '464b0624-48f4-451a-8016-f46109d82382',
            slug: 'mlp-claims-sme-risks',
            questionnaireOverride: {
              passScore: 2,
              mix: {
                easy: 0,
                medium: 2,
                hard: 0,
              },
            },
          },
        ],
      },
      {
        id: '349708b6-4bdc-49a5-a851-fbd4d574b56d',
        slug: 'soft-skills',
        modules: [
          {
            id: '215cfe29-0599-4e3d-9bb6-60e4b90abebc',
            slug: 'a-guide-to-workplace-integrity',
          },
          {
            id: '4ab5a337-e63a-493a-9093-b046b3aafefb',
            slug: 'communication-essentials',
          },
          {
            id: 'ddacc2cf-c989-4776-9f3a-435e97d94e1f',
            slug: 'telephone-etiquette',
          },
          {
            id: 'f9ea0233-4e64-4e3f-a3cd-dec4a59b62d9',
            slug: 'creating-and-delivering-business-presentations',
          },
          {
            id: '059e56f7-61d5-4c74-b1e0-140dd0cae6c1',
            slug: 'be-the-best-version-of-yourself',
          },
        ],
      },
      {
        id: 'cb299f5f-2a2e-48a7-a555-df263578556c',
        slug: 'mergers-and-acquisitions',
        modules: [
          {
            id: 'cc482d4a-1691-41e7-93f6-d8a644897a6e',
            slug: 'mergers-and-acquisitions–insurance-issues',
          },
        ],
      },
      {
        id: 'b6e92936-69ae-4393-b518-3d5b8d4f3dfd',
        slug: 'introduction-to-underinsurance-and-inflation',
        modules: [
          {
            id: '92c8b8a8-a900-4353-9a67-23f88a39ccc1',
            slug: 'introduction-to-underinsurance-and-inflation',
          },
        ],
      },
      {
        id: '78626287-b0ac-4384-a96c-77d172323d18',
        slug: 'demystifying-alternative risk-transfer-art',
        modules: [
          {
            id: 'f35cf08c-2d4e-4ac0-af1f-7b0a64c9f9f3',
            slug: 'demystifying-alternative-risk-transfer',
          },
        ],
      },
      {
        id: 'f509e87d-97bb-411d-a145-a0dda3f0a5d4',
        slug: 'conduct-and-risk-meeting-our-customers-needs-together',
        modules: [
          {
            id: '15a314fd-d637-4514-a6ca-245cc62ab413',
            slug: 'conduct-risk-partner-meeting-our-customers-needs',
          },
        ],
      },
      {
        id: 'c306cd2c-4df9-4206-85cc-a33d0bcf6762',
        slug: 'introduction-to-sales',
        modules: [
          {
            id: '4b0fe2b8-6145-4200-bfd9-6dbca2bf8fda',
            slug: 'sales-introduction-to-the-sales-process',
          },
          {
            id: '753cd031-f71d-46dd-a552-cd535cc009fe',
            slug: 'sales-prospecting-one',
          },
          {
            id: 'ab5edfa3-1e1d-4490-9f81-4dc7265fad89',
            slug: 'sales-prospecting-two',
          },
          {
            id: 'd5947c7d-fad0-4377-9bc2-27846d6f3f3c',
            slug: 'sales-connecting-one',
          },
          {
            id: 'a2517287-9ad0-4998-8e1e-5ac8a2bf9330',
            slug: 'sales-connecting-two',
          },
          {
            id: 'd5e5802c-97ae-4925-939d-0c179a5190e6',
            slug: 'sales-needs-discovery-one',
          },
          {
            id: '91ed9620-2114-4f6d-ac2a-89e0469545e4',
            slug: 'sales-needs-discovery-two',
          },
          {
            id: 'f095540c-8180-499f-b4f4-9fab6678cb0d',
            slug: 'sales-solution-proposal',
          },
          {
            id: 'd20d8177-83f8-4fb6-9f31-9a7113969f1e',
            slug: 'sales-objection-handling',
          },
          {
            id: '5b46fcd4-c0fc-4432-9055-9d5ea111e1ba',
            slug: 'sales-closing',
          },
        ],
      },
      {
        id: 'd68963e3-4b1b-4af9-9d70-c9399c58d809',
        slug: 'toolbox-talks',
        modules: [
          {
            id: 'e9cd94d5-31eb-4792-acfc-548ccd72390e',
            slug: 'keeping-your-high-performers-performing',
          },
          {
            id: 'fa94d80f-8bad-46b6-a0c5-b2646f69e817',
            slug: 'the-extra-one-percent-performance-edge',
          },
          {
            id: 'a8d8f4ee-4f2c-4820-b9b3-dfbca89844c2',
            slug: 'inspiring-high-performance-from-your-team-when-the-pressure-is-on',
          },
          {
            id: '7d4a8051-1db0-43be-905c-70c5a8ba7921',
            slug: 'adopting-a-sales-mindset',
          },
          {
            id: 'dea0e533-61c3-4fee-89a9-eda7f3c97b20',
            slug: 'networking-for-success',
          },
        ],
      },
      {
        id: 'f2aabd5b-8118-4cbb-aa4a-c0e719f8d459',
        slug: 'pragmatic-leadership',
        modules: [
          {
            id: 'ac79b1d4-70e8-42f0-a0f8-440d4d0daa96',
            slug: 'what-makes-a-great-leader',
          },
          {
            id: '877a3e00-c333-45f2-99cf-1f67b11be8d2',
            slug: '4-habits-of-leadership',
          },
          {
            id: 'b57fc6f1-9b65-4279-9fc0-bf7cd5f5af9d',
            slug: 'key-ingredients-of-building-your-team',
          },
          {
            id: '5a1df134-7ab1-428d-8245-a1feaf150a5f',
            slug: 'bringing-your-leaderships-skills-together',
          },
        ],
      },
      {
        id: '6bf4d444-daf6-4a42-b9bc-3e6e7a8d3d44',
        slug: 'reviewing-contracts-for-insurance-implications',
        modules: [
          {
            id: '14f25a91-3631-499b-b22b-1cf5519938f8',
            slug: 'reviewing-contracts-for-insurance-implications',
          },
        ],
      },
      {
        id: '96217a28-8620-4869-9b36-ab53bc19cc14',
        slug: 'presenting-employers-liability-risks',
        modules: [
          {
            id: '1a682c09-e4f0-4c88-96fe-fb0dab10b300',
            slug: 'introduction-to-presenting-employers-liability-risk',
          },
        ],
      },
      {
        id: '33c63463-c631-4478-9115-ae51dda1931d',
        slug: 'introduction-to-professional-indemnity-insurance',
        modules: [
          {
            id: '74be6f3b-1121-46e9-b709-0022b7cdcbab',
            slug: 'how-liability-arises',
          },
          {
            id: '9f3e3fd8-f9bc-4fe7-a4ac-e57a84092204',
            slug: 'professional-Indemnity-policy-cover',
          },
          {
            id: 'f15ee027-10c1-4584-a78d-c7ce932a348d',
            slug: 'professional-indemnity-vs-cyber',
          },
        ],
      },
      {
        id: '5d12de43-63e2-4939-b7ab-2f5677f9cc1c',
        slug: 'lmi-business-interruption',
        modules: [
          {
            id: 'a073796c-1b3a-4418-911d-2269e6ea0004',
            slug: 'business-interruption',
          },
        ],
      },
      {
        id: '8221d7be-45de-47ee-aba9-33124c54d4a8',
        slug: 'motor-fleet',
        modules: [
          {
            id: 'b4a6575e-6898-4358-989c-fec51804f2b7',
            slug: 'electric-vehicles-technical-training',
          },
        ],
      },
      {
        id: '550971f5-d422-4a1a-9b61-6b167e40b959',
        slug: 'your-behaviours-matter',
        modules: [
          {
            id: '387a74bf-36de-4a0d-afad-5c0beccc6210',
            slug: 'your-behaviours-matter',
          },
          {
            id: '88d0379d-f21a-4fd4-a232-90e59d778a80',
            slug: 'flexing-your-approach-to-drive-engagement',
          },
          {
            id: '40d68ddd-5ab4-42dd-9710-35d67ecdbd8e',
            slug: 'motivating-your-team-for-improved-performance',
          },
        ],
      },
      {
        id: '067d1298-cbed-4fa4-b3da-de09893da510',
        slug: 'nurturing-your-mental-health',
        modules: [
          {
            id: '8b7c404d-fc76-42a5-adcc-60e2a50cb762',
            slug: 'nurturing-your-mental-health',
          },
        ],
      },
      {
        id: '76c64d1c-38a4-4ac5-824c-31ef12b91ac7',
        slug: 'change-management-series',
        modules: [
          {
            id: 'ba69bfed-e48e-4856-bc49-7798e672a609',
            slug: 'change-management-managing-change',
          },
          {
            id: '7be7047e-5c33-4d6f-b6ad-f15620195b92',
            slug: 'change-management-dealing-with-change',
          },
          {
            id: '49b9cb8a-a18f-4a06-9fc3-1487599480d3',
            slug: 'change-management-leading-change',
          },
        ],
      },
      {
        id: '9ddda366-d724-4ef0-8a75-248eda696817',
        slug: 'hybrid-management',
        modules: [
          {
            id: 'b55e0a61-ed38-4904-a78e-8af63f6a58ab',
            slug: 'hybrid-management-key-communication-skills-and-self-awareness',
          },
          {
            id: '8d318909-37b2-4691-b20b-7555a3bf9e46',
            slug: 'hybrid-management-setting-smarter-objectives-remotely',
          },
          {
            id: '70291d56-eb93-4627-88ee-6ed4fe53df05',
            slug: 'hybrid-management-effective-1-2-1s-and-appraisals-in-a-hybrid-world',
          },
          {
            id: 'fa49c144-e4be-48d2-b5c8-c8ecaf26d361',
            slug: 'hybrid-management-deliver-feedback-for-positive-outcomes',
          },
          {
            id: 'daceba8f-82ae-4db6-a7d8-39597b13e0d2',
            slug: 'hybrid-management-manage-underperformance',
          },
        ],
      },
      {
        id: 'f7673de3-978f-4260-9f29-46f2c6700c0b',
        slug: 'employee-series',
        modules: [
          {
            id: '3639f37d-09bb-4261-a60b-7447a44d7d65',
            slug: 'employee-series-agreeing-smarter-objectives',
          },
          {
            id: 'f84e9463-fc90-48f2-9b06-abd93e50b9fa',
            slug: 'employee-series-effective-1-2-1s-and-appraisals',
          },
          {
            id: 'be735670-e3fa-4712-8dac-05bead879e4d',
            slug: 'employee-series-key-communication-skills-and-self-awareness',
          },
        ],
      },
      {
        id: '9ecd64a0-66a5-4ef7-8ba6-f0a39841849f',
        slug: 'rradar-people-management-series',
        modules: [
          {
            id: '17dfae1f-d43c-479d-839c-a9c7a49c6459',
            slug: 'managing-sickness-absence',
          },
          {
            id: '4b07c6a5-3a35-4e07-bd58-5b0750305d90',
            slug: 'performance-management',
          },
          {
            id: '2ce14dc2-9690-47b2-9960-a22a95afde42',
            slug: 'discrimination',
          },
        ],
      },
      {
        id: '80ea6f13-2d75-4b83-9c42-02ef20d57166',
        slug: 'actus-employeee-wellbeing-and-resilience',
        modules: [
          {
            id: 'dc5963bb-ee3c-4460-9c2d-70e6e3a2cd05',
            slug: 'module-1-resilience-and-wellbeing',
          },
        ],
      },
      {
        id: 'f0a2b81c-6127-4e34-a22d-e24cd5f58c5f',
        slug: 'technical-real-estate',
        modules: [
          {
            id: '13167efc-a419-46ef-bb74-635fcd50ba30',
            slug: 'real-estate-market-segmentation-and-key-issues',
          },
          {
            id: '828ff90e-02c5-4373-87a7-25be0989ab4e',
            slug: 'real-estate-construction-leases-and-lenders-in-the-real-estate-sector',
          },
          {
            id: '268902a6-97d1-4b7f-be83-af44371b9102',
            slug: 'real-estate-property-insurance-key-cover-issues-in-property-owners-policies',
          },
          {
            id: 'c2df1d8c-fbde-4b74-864d-7fe56f83944c',
            slug: 'real-estate-income-protection-loss-of-rent-and-alternative-accommodation',
          },
          {
            id: 'b46f51b2-5afc-40d7-8f32-730a517216ab',
            slug: 'real-estate-additional-policies-to-consider-for-the-real-estate-sector',
          },
        ],
      },
      {
        id: '3f0b220a-d4d9-49c0-a49c-fc90c26de19b',
        slug: 'thriving-in-the-hard-market',
        modules: [
          {
            id: '645f1dca-bfec-4f33-a299-1d5bf8ffaba8',
            slug: 'the-dynamics-of-the-insurance-market-cycle',
          },
          {
            id: 'd149e364-011a-4e68-b617-23c1f9677c5b',
            slug: 'trading-in-a-hard-market',
          },
          {
            id: '3879b79c-87f8-4c9d-9464-947edff9f596',
            slug: 'your-personal-effectiveness-in-a-hard-market',
          },
        ],
      },
      {
        id: '8564b263-b8f3-47d5-b2d8-680bb2f38ea6',
        slug: 'marketing',
        modules: [
          {
            id: '37ee44b5-faca-43b7-8dc6-db2f82775517',
            slug: 'introduction-to-social-media',
          },
          {
            id: 'fc76de0b-07e5-480a-b872-f7a399177e39',
            slug: 'introduction-to-linkedin',
          },
          {
            id: '03bd1c66-a6b9-40c3-9d21-748fec83cdd7',
            slug: 'how-to-create-a-linkedIn-profile',
          },
        ],
      },
      {
        id: '07ceadc8-32da-4dab-9bef-da023c3388eb',
        slug: 'technical-construction',
        modules: [
          {
            id: '7abf78eb-ebbf-4fc7-9736-2ed3885843cb',
            slug: 'construction-market-segmentation-and-key-issues',
          },
          {
            id: 'd3e90929-0f83-4492-ae00-4aaf4cb0b781',
            slug: 'construction-liability-issues-in-the-construction-sector-part-1',
          },
          {
            id: '2cd9fd2d-7003-46c7-8cf4-aa77e6e4cd7c',
            slug: 'construction-liability-issues-in-the-construction-sector-part-2',
          },
          {
            id: '4a5cea33-a20c-4bcf-a987-702eee24d306',
            slug: 'construction-contractors-all-risks-erection-all-risks-and-bi-issues',
          },
          {
            id: '3b33b107-5c3d-4f96-a280-fb0bda181bb7',
            slug: 'construction-other-cover-options-for-the-construction-industry',
          },
        ],
      },
      {
        id: '32f67180-3d5b-4522-82d8-aec3c6be0971',
        slug: 'defeating-work-burnout',
        modules: [
          {
            id: 'cfe0988f-b3ba-4885-94e4-ee59c18b14a5',
            slug: 'what-is-work-burnout',
          },
          {
            id: '9c1521c2-66b7-4320-aab2-4b04ce7fc5a7',
            slug: 'stages-of-burnout',
          },
          {
            id: '010aa87d-695e-432d-995d-5298413ac5c6',
            slug: 'how-to-talk-to-your-boss-about-burnout',
          },
          {
            id: '1877c0ed-e65d-485a-847e-c6e22734b1eb',
            slug: 'myths-about-burnout',
          },
        ],
      },
      {
        id: '1d903ecd-b454-426a-8d43-a44307fd4ab7',
        slug: 'sales-in-a-changing-environment',
        modules: [
          {
            id: '97df8808-56fa-47b9-87fa-835364226218',
            slug: 'sales-process-overview',
          },
          {
            id: 'd0ea0901-b7d5-4167-a047-a242649925aa',
            slug: 'adapting-to-a-hardening-market-and-strategic-selling',
          },
          {
            id: '8ef25070-8ea4-4c43-9c5e-907c4c6dced9',
            slug: 'becoming-a-solutions-provider',
          },
          {
            id: '9606c79a-0404-4d16-b68d-0628ad7ce4f7',
            slug: 'social-selling-and-virtual-networking',
          },
          {
            id: '8a0229c9-4d64-4199-8a4c-1fa459f796f9',
            slug: 'effective-communication-in-a-virtual-world',
          },
          {
            id: '1080946a-0508-48a2-8730-002fb14cd5cb',
            slug: 'virtual-meetings',
          },
        ],
      },
      {
        id: '31e36ecf-9b1e-4831-9193-665e8534fe0c',
        slug: 'technical-manufacturing',
        modules: [
          {
            id: '5b5efa46-a19a-4418-87d3-104ea23c6b6e',
            slug: 'manufacturing-property-insurance-issues-and-implications',
          },
          {
            id: 'ec8fa890-105b-4f90-b6e9-8e4eb93ce216',
            slug: 'manufacturing-business-interruption-issues-and-implications-part-1',
          },
          {
            id: 'ac0f1886-4695-4bbf-a5ba-6b81e4b583c1',
            slug: 'manufacturing-business-interruption-issues-and-implications-part-2',
          },
          {
            id: 'e3b95373-1c4c-45ac-bc64-46edf2df64e5',
            slug: 'manufacturing-liability-and-motor-insurance-issues-and-implications',
          },
        ],
      },
      {
        id: '0634f0ad-b1be-4a8d-8975-dd46dee3742d',
        slug: 'impactful-communication-in-a-virtual-trading-environment',
        modules: [
          {
            id: 'b6287211-c150-469c-9db2-1d260c4dd260',
            slug: 'virtual-meetings-setting-yourself-up-for-success',
          },
          {
            id: '933c8eeb-07cf-41df-93cd-ff16ee3fbaf6',
            slug: 'impactful-communications-and-rapport',
          },
        ],
      },
    ],
  },
  content: {
    footer: {
      panels: [
        {
          title: '',
          body: '',
          list: [
            {
              title: 'Disclaimer',
              routeName: 'Disclaimer',
            },
            {
              title: 'Terms &amp; Conditions',
              routeName: 'Terms',
            },
          ],
        },
        {
          title: '',
          body: '',
          list: [
            {
              title: '&copy;2025 Ignition NBS Ltd All Rights Reserved',
              routeName: '',
            },
            {
              title:
                'Broker Elevate is provided by <a href="https://www.ignitionnbs.co.uk" target="_blank">Ignition New Business Solutions Limited</a>',
              routeName: '',
            },
            {
              title: 'Trading address: 5th Floor, 82 King Street, Manchester M2 4WQ',
              routeName: '',
            },
            {
              title: 'Registered address: 20 Gracechurch Street, London EC3V 0BG',
              routeName: '',
            },
          ],
        },
      ],
    },
    home: {
      public: {
        title: 'Welcome to our Broker Elevate Community',
        body: [
          {
            title: '',
            body: 'Broker Elevate is our proposition, born out of the AXA National Trading Centre (NTC). Here at NTC, we believe we can make a real difference to you and your brokerage. We want to be more than just your Insurer and through Broker Elevate, we hope to achieve this.',
          },
          {
            title: '',
            body: 'As you explore this dedicated Broker Elevate website, you’ll find more information about the proposition itself, what it means to you, and gain access to some online tools, all from one place.',
          },
          {
            title: '',
            body: 'Your success means everything to us, so we continually invest to provide you with the support you need to reach your goals.',
          },
          {
            title:
              'Chris Maguire, Branch Manager National Trading Centre, talks about the Broker Elevate proposition',
            body: '',
          },
        ],
      },
    },
    register: {
      signUp: {
        title: 'Sign Up',
        body:
          '<p>Signing up for Brokerwise is free and easy, you can use a traditional email log-in or use ' +
          'one of your other accounts instead. Just select from the list below.</p>',
        meta: {
          title: 'Log In',
          description: "Select your log in option below and we'll help you on your way",
        },
        aboutYou: {
          title: 'About you',
          firstName: 'First Name',
          lastName: 'Last Name',
          cpdAnnualDate: "CPD Annual Date (Leave blank if you don't have one)",
        },
        aboutYourCompany: {
          title: 'About your company',
          brokersName: 'Broker Name',
          lastName: 'Last Name',
          cpdAnnualDate: 'CPD Annual Date (if you have one)',
        },
        loginDetails: {
          title: 'Login details',
          emailAddress: 'Email address',
          password: 'Password',
        },
        email: {
          title: 'Sign up with Email',
          body: '<p>Please complete the form below and click the <strong>Sign Up</strong> button.</p>',
          button: 'Sign Up',
          passwordResetLink: 'Need to reset your password?',
        },
        facebook: {
          title: 'Sign up with Facebook',
        },
        google: {
          title: 'Sign up with Google',
        },
        linkedin: {
          title: 'Sign up with LinkedIn',
        },
        socialNotSupported: {
          title: 'Social logins not available on this browser',
          body: "If you'd like to use your social account to sign up and login to Broker Elevate please use a modern browser such as Google Chrome, Apple Safari, Microsoft Edge or Firefox.",
        },
        oauthCallback: {
          title: 'Sign up - additional information',
          body: '',
          button: 'Complete sign up',
          pleaseWait: 'Please wait... Getting things ready for you!',
        },
        verificationSent: {
          title: 'Verification Email Sent',
          body:
            "<p>Thank you for registering! We have sent you a verification email, if you don't see it in your inbox shortly please check your spam folder.</p>" +
            '<p>Please click on the link in that email to confirm your sign up.</p>',
        },
        verificationSuccess: {
          title: 'Welcome to Broker Elevate.',
          body: 'Your account is now registered and active. <a href="/login">Log In</a> and start learning!',
        },
      },
      invite: {
        title: 'Invite a new user',
        body: '',
        button: 'Send Invitation',
      },
      success: {
        title: '',
        body: '',
      },
      passwordRules: {
        title: 'Tip: Your secure password needs to meet the following rules:',
        body: {
          type: 'dynamic',
          rules: [
            {
              listItem: 'At least 12 characters',
              minimum: 12,
              type: 'length',
            },
            {
              listItem: 'At least one uppercase letter',
              regularExpression: /[A-Z]/,
              type: 'regularExpression',
            },
            {
              listItem: 'At least one lowercase letter',
              regularExpression: /[a-z]/,
              type: 'regularExpression',
            },
            {
              listItem: 'At least one digit',
              regularExpression: /[0-9]/,
              type: 'regularExpression',
            },
            {
              listItem: 'At least one of the following: !@#$%^&*:./?=+-_[]{}()',
              regularExpression: /[!@#$%^&*:./?=+_[\]{}()-]/,
              type: 'regularExpression',
            },
          ],
        },
      },
    },
    logIn: {
      title: 'Log In',
      body: [
        {
          title: '',
          body: 'Welcome to Broker Elevate, please log in below using your Broker Elevate or Brokerwise portal account details.',
        },
        {
          title: '',
          body: '<span style="color:#027080">Note: Your account details on this site are not the same as your other AXA login details. If you don\'t have an account here you can create one by signing up using the button on the right.</span>',
        },
      ],
      meta: {
        title: 'Log In',
        description: "Select your log in option below and we'll help you on your way",
      },
      email: {
        title: 'Log In with Email',
        button: 'Log In',
        passwordResetLink: 'Need to reset your password?',
      },
      facebook: {
        title: 'Continue with Facebook',
      },
      google: {
        title: 'Continue with Google',
      },
      linkedin: {
        title: 'Continue with LinkedIn',
      },
      verificationRequired: {
        // title: 'Verification Required',
        title: 'Account Not Verified / Recognised',
        body: [
          {
            title: '',
            // body: 'Sorry, you haven\'t verified your account yet, please check your emails (including your spam folder) for the email containing a verification button and click on it to confirm your account.'
            body: "Sorry, we don't recognise the account details you have entered, if you have recently signed-up please check your inbox (and spam folder) for our verification email and click the link it contains.",
          },
          {
            title: '',
            // body: 'If you can\'t find the verification email, please click on the button below to have it sent to you again.'
            body: "If you haven't created an account yet please go to the Sign Up page to self register or if you have already signed up, and are using the correct email address to log-in with, request a password reset on the Log In page",
          },
        ],
        // button: 'Re-send verification email'
        button: '',
      },
      verificationEmailError: {
        title: 'Unrecognised Account',
        body: [
          {
            title: '',
            body: "Sorry, we couldn't send you a verification email because we cannot find the email address you entered in our system.",
          },
          {
            title: '',
            body: "If you haven't signed up already, please do that by using the button below. Or try again using the email address you registered on Broker Elevate with.",
          },
        ],
        button: 'Sign Up Here',
      },
      verificationEmailSent: {
        title: 'Verification Email Sent',
        body: [
          {
            title: '',
            body: 'Thanks for signing up for Broker Elevate!',
          },
          {
            title: '',
            body: "We've sent you a verification email, please check your emails (including your spam folder) for an email containing a verification button and click on it to confirm your account.",
          },
        ],
      },
    },
    passwordReset: {
      request: {
        title: 'Reset Your Password',
        body: "<p>If you've forgotten your password or need to change it, enter your email address below and click the <strong>Request password reset link</strong> button and we'll send you an email containing a link to a page where you can easily create a new password for your account.</p>",
        button: 'Request password reset link',
      },
      linkSent: {
        title: 'Password Request Link Sent',
        body: "<p>We've sent you an email with instructions for resetting your password. </p><p>If you don't receive this email, please try checking your junk mail folder. If the email is not in your junk folder, please contact support for assistance.</p>",
      },
      setPassword: {
        title: 'New Password',
        body: '<p>Please enter your new password below.</p>',
        button: 'Reset password',
      },
      success: {
        title: 'Password Reset',
        body: 'Your password was successfully reset. You can now <strong>Log In</strong> using your new details.',
        button: 'Go to Log In page',
      },
    },
    about: {
      title: 'Bringing together the Broker Elevate community',
      image: 'about.png',
      meta: {
        title: 'About',
        description: 'Bringing together the Broker Elevate community',
      },
      body: [
        {
          title: '',
          body: 'As a broker managed by the AXA National Trading Centre, you are now a part of the Broker Elevate community – hello and welcome!',
        },
        {
          title: '',
          body: 'So what is Broker Elevate? Broker Elevate is our structured pathway to growing and partnering with us at AXA.',
        },
        {
          title: '',
          body: 'Over the past 6 years, you have helped us year on year to deliver our profitable growth ambitions and we see that you are part of us achieving the same ambitions for the future.',
        },
        {
          title: '',
          body: 'As part of the Broker Elevate Community, you’ll have a clear and transparent view of what you can expect from us based on your tier, this starts with the level of account management service you will receive, right through to what value-added benefits you can access but we want more than just that, we want to work with you, support you and inspire you to grow through our tiers.',
        },
      ],
    },
    proposition: {
      title: 'The Broker Elevate Proposition',
      image: 'proposition.png',
      meta: {
        title: 'Proposition',
        description:
          'Our focus and passion at AXA is on delivering excellent service and building strong relationships',
      },
      body: [
        {
          title: '',
          body: "Our focus and passion at AXA is on delivering excellent service and building strong relationships. This is even more crucial given the challenging couple of years we've all had.",
        },
        {
          title: '',
          body: 'Knowing how you feel about us is extremely valuable and your feedback has directly helped us to shape our proposition, Broker Elevate.We’ve listened and acted, enabling us to design a proposition to meet your requirements.',
        },
      ],
      list: [
        {
          title: 'Visibility and Communication',
          body: 'Keeping connected is important. Our proposition ensures we are maintaining visibility and working closely with you to maintain regular communication. Through our Account Management structure, our planned events or our communication channels, we want to stay engaged with you and keep you all updated.',
          icon: 'icon-visibility.png',
        },
        {
          title: 'Relationship Management',
          body: 'We’ve created a more structured engagement strategy to ensure we continue to build closer relationships and maintain a high trading service level.',
          icon: 'icon-relationship.png',
        },
        {
          title: 'Helping and supporting you',
          body: 'We’re giving you access to a whole suite of digital training through our Brokerwise platform available to you and your employees.',
          icon: 'icon-support.png',
        },
      ],
    },
    theTiers: {
      title: 'The Tiers',
      image: 'the-tiers.png',
      media: '2023_AXA_NTC_Broker_Elevate_Tier_Illustration.pdf',
      meta: {
        title: 'Proposition',
        description:
          'Our focus and passion at AXA is on delivering excellent service and building strong relationships',
      },
      body: [
        {
          title: '',
          body: 'The proposition has been designed to deliver a structured progression in benefits to reflect the level of contribution you make to our strategic objectives and the benefits increase as you progress through the tiers.',
          button: 'Download our 2023 proposition',
        },
        {
          title: '',
          body: '',
          button: 'Previous Tier',
        },
        {
          title: '',
          body: '',
          button: 'Next Tier',
        },
      ],
      tableTitles: ['Account Management', 'Service Expectation', 'Deal', 'Value Added Benefits'],
      list: [
        {
          title: 'Entry',
          description: '',
          accountManagement:
            'Here to help when you need us.<br/><br/>' +
            'We value your business, but we also understand that you may not have the need to talk to us every week, or even every month. We want to make sure that when you do need us, we’ll be there, so you’ll have access to a dedicated Telephone Account Manager.',
          serviceExpectation: [
            'Available to support you when you need us',
            'Supplemented by digital marketing',
          ],
          deal: 'N/A',
          valueAddedBenefits: ['Access to Brokerwise, our online personal development platform'],
          headerColor: '#9fbeaf',
          bodyColor: '#dde8e3',
        },
        {
          title: 'Kickstart',
          description: 'Kickstarting our relationship',
          accountManagement:
            'We understand how important it is for us to get to know you and your business. <br/><br/>Managing a small panel of brokers, your dedicated Telephone Account Manager will be on hand to help you understand AXA’s appetite and proposition, as well as getting to know your people, your business and kickstarting our relationship.',
          serviceExpectation: [
            'Quarterly Business Review to share performance and improve how we work together',
            'Working together to retain cases and target new opportunities',
            'Proactive pipeline management',
            'Contact strategy agreed',
            'Supplemented by digital marketing',
          ],
          deal: 'N/A',
          valueAddedBenefits: ['Access to Brokerwise, our online personal development platform'],
          headerColor: '#67cedd',
          bodyColor: '#cdeff4',
        },
        {
          title: 'Bronze',
          description: 'Our bronze value added package is designed to build relationships.',
          accountManagement:
            'Keeping connected is important when building relationships.<br/><br/>You’ll be assigned your own dedicated Telephone Account Manager who will be on hand to help with any queries. They will work with you to help retain your clients and encourage new business opportunities.',
          serviceExpectation: [
            'Half-yearly Business Review to share performance and improve how we work together',
            'Working together to retain cases and target new opportunities',
            'Supplemented by digital marketing',
          ],
          deal: 'N/A',
          valueAddedBenefits: [
            'Access to Brokerwise, our online personal development platform',
            'Invitations to general, regulatory and rradar webinars',
          ],
          headerColor: '#c9b16a',
          bodyColor: '#f1ebda',
        },
        {
          title: 'Silver',
          description:
            'Our silver package encourages growth with access to training and marketing opportunities.',
          accountManagement:
            'Mutual growth doesn’t happen without a well-thought-out plan.<br/><br/>Your Telephone Account Manager will work with you to create a joined-up business plan to establish your business goals and also help to identify ways in which we can support your growth through training and development opportunities.',
          serviceExpectation: [
            'Quarterly Business Review to share performance and improve how we work together',
            'Opportunity to work together to create a joined-up business plan',
            'Large case and sales pipeline management',
            'Provision of monthly account statement',
            'Working together to retain cases and target new opportunities',
          ],
          deal: 'Profit Share<br/><br/>Each year we will look to offer a deal that rewards partnership and profitable long-term growth. Qualifying criteria will apply.',
          valueAddedBenefits: [
            'Access to Brokerwise, our online personal development platform',
            'Invitations to general, regulatory and rradar webinars',
            'Touch points from Branch Leadership Team',
          ],
          headerColor: '#aeb1b1',
          bodyColor: '#d6d8d8',
        },
        {
          title: 'Gold',
          description:
            'This package has been designed to give you a range of tools to help you take a wider view of your business and help accelerate the growth of your business.',
          accountManagement:
            'Each brokerage has a culture, a personality, a set of objectives and a dream and it’s the role of your Account Manager to understand all of these.<br/><br/>Your Account Manager will be managing a small panel of brokers enabling them to connect and develop a strong relationship with you so they can tailor the service you receive to suit your needs.',
          serviceExpectation: [
            'Regular contact to manage individual requirements and share AXA appetite',
            'Bespoke contact strategy agreed with you, to include face to face visits where necessary',
            'Access to Branch Senior Management Team where required',
          ],
          deal: 'Profit Share<br/><br/>Each year we will look to offer a deal that rewards partnership and profitable long-term growth. Qualifying criteria will apply.',
          valueAddedBenefits: [
            'Complimentary access, for the calendar year only, to the entry level endcustomer.co.uk tool (5 users with 300 reports a month)',
            'Pre-funded web review by Ignition',
            'Access to Brokerwise, our online personal development platform',
            'Invites to technical experience events',
          ],
          headerColor: '#fcd385',
          bodyColor: '#fde4b6',
        },
        {
          title: 'Platinum',
          description:
            'The Platinum package helps you to identify areas for growth and improvement in your business which can elevate performance, increase business and maintain and improve client retention.',
          accountManagement:
            'Managing a small panel of brokers, your Account Manager will develop a strong and trusting relationship with you. <br/><br/>They understand the key challenges you face and will work with you to identify the best possible mutually beneficial outcome. <br/><br/>With careful planning, strategic thinking and frequent account reviews, your Account Manager will understand your individual requirements and ensure you maximise the opportunities available to you through the Elevate proposition.',
          serviceExpectation: [
            'Exclusives agreed within 48 hours subject to trade / appetite',
            'Diarised, regular face to face visits',
          ],
          deal: 'Bespoke Deal<br/><br/>Bespoke deals reviewed annually based on book performance and planned growth. Qualifying criteria will apply.',
          valueAddedBenefits: [
            'Complimentary access, for the calendar year only, to the entry level endcustomer.co.uk tool (5 users with 300 reports a month)',
            'Pre-funded web review by Ignition',
            'x1 Ignition Marketing consultancy visit',
            'Access to Brokerwise, our online personal development platform',
            'Invites to technical experience events',
          ],
          headerColor: '#b6d1ee',
          bodyColor: '#e9f1fa',
        },
      ],
    },
    brokerwisePublic: {
      title: 'Brokerwise Modules',
      image: '',
      meta: {
        title: 'Brokerwise Modules',
        description: 'Brokerwise is an online personal development platform for Brokers',
      },
      body: [
        {
          title: '',
          body: 'Brokerwise is an online personal development platform for Brokers. Work your way through self-contained training modules, all whilst clocking up time towards your CPD target! A growing library of content includes sales training through to HR.',
        },
        {
          title: '',
          body: 'To start a new module, flip its card over and click on its "play" button, and you will be on your way!',
        },
      ],
    },
    brokerwiseLoggedIn: {
      title: 'Welcome to your Brokerwise Module collection',
      image: '',
      meta: {
        title: 'Brokerwise Modules',
        description: '',
      },
      body: [
        {
          title: '',
          body: "Your collection shows your progress within Broker Elevate's Brokerwise Modules. You'll see empty spaces for the training modules you have yet to collect. When you successfully complete a module, it will shown in your collection.",
        },
        {
          title: '',
          body: 'Each training module provides a short description, their estimated time to complete and a guide to the skill levels, plus any prerequisite modules required to complete them.!',
        },
        {
          title: '',
          body: 'To start a new module, just click on its "play" button, and you will be on your way. When you finish a new module, you will see the new card added to your collection!',
        },
      ],
    },
    contacts: {
      title: 'Want to say hello?',
      image: 'contact.png',
      meta: {
        title: 'Contact',
        description: 'Want to say hello?',
      },
      body: [
        // see contactsDetails
      ],
    },
    contactsDetails: {
      title: 'These are the Broker Elevate teams to speak to…',
      image: '',
      meta: {
        title: 'Contact',
        description: 'Want to say hello?',
      },
      body: [
        {
          title: 'NTC Trading Teams',
          body: [],
          list: [
            {
              title: 'New Business - Property and Casualty',
              email: '',
              phone: '0345 600 3701',
            },
            {
              title: 'Existing Business - Property and Casualty ',
              email: '',
              phone: '0330 041 6148',
            },
            {
              title: 'Motor Fleet and Green Card requests',
              email: '',
              phone: '0345 600 3032',
            },
          ],
        },
        {
          title: 'NTC Management Team',
          body: [],
          list: [
            {
              title: 'Chris Maguire - Branch Manager',
              email: 'chris.maguire@axa.co.uk',
              phone: '01204 815729',
            },
            {
              title: 'Mark Pickles - Sales Manager',
              email: 'mark.pickles@axa-insurance.co.uk',
              phone: '01204 815775',
            },
            {
              title: 'Andrew Chamberlain - Underwriting Manager',
              email: 'andrew.chamberlain@axa-insurance.co.uk',
              phone: '01204 815195',
            },
            {
              title: 'Paula Parslow - Operations Manager',
              email: 'paula.parslow@axa-insurance.co.uk',
              phone: '01204 815736',
            },
          ],
        },
        {
          title: 'Broker Elevate',
          body: [
            {
              title: '',
              body: 'To learn more about the Broker Elevate community, get in touch:',
            },
          ],
          list: [
            {
              title: 'Lesia Szczur - Broker Development Leader',
              email: 'lesia.szczur@axa-insurance.co.uk',
              phone: '07866 032 394',
            },
          ],
        },
        {
          title: 'Other useful contact details',
          body: [
            {
              title: '',
              body: 'All submissions should be sent to <a href="mailto: AxaNTC2.INS@axa-insurance.co.uk">AxaNTC2.INS@axa-insurance.co.uk</a> <br/><br/>If you need to get in touch with another area of the business, <a href="https://www.axaconnect.co.uk/contact-us/find-your-local-branch/bolton-national-trading-centre/" target="_blank">click here</a> to access these numbers',
            },
          ],
          list: [],
        },
      ],
    },
    terms: {
      title: 'Agreement for use of Ignition NBS Limited’s brokerelevate.co.uk',
      image: 'home.png',
      meta: {
        title: 'Terms and Conditions',
        description:
          'These terms and conditions will apply when you access brokerelevate.co.uk (“broker elevate”)',
      },
      body: [
        {
          title: 'Important Notice',
          body: 'These terms and conditions will apply when you access brokerelevate.co.uk (“brokerelevate”). Before accessing the website we ask that you read these terms and conditions carefully. You will also find that they contain some useful advice on your responsibilities to Ignition NBS Limited (“Ignition”) and the basis on which you may access broker elevate. We recommend that you keep a copy of these terms and conditions for future reference.',
        },
        {
          title: '',
          body: 'By accessing broker elevate you acknowledge and agree to be bound by these terms. If you do not agree to these terms you must not use broker elevate.',
        },
        {
          title: '',
          body: 'We reserve the right, at our sole discretion, to modify or replace any of these terms, or change, suspend, or discontinue the access rights to broker elevate (including without limitation, the availability of any feature, database, or content) at any time, by notice given on the site or by changing the terms as accessible on the site. We may also impose limits on certain features and services or restrict your access to parts or all of the services without notice or liability. You are responsible at all times for complying with the current version of the terms. We recommend that you periodically check these terms. Your continued use of the website continues acceptance of those changes.',
        },
        {
          title: '',
          body: 'We do not guarantee that broker elevate, or any content accessible through the website, will be free from errors or omissions.',
        },
        {
          title: '',
          body: ' BrokerElevate.co.uk is a product of Ignition, a company registered in England and Wales with its registered office at 5 Old Broad Street, London, EC2N 1AD under number 04857208',
        },
        {
          title: '',
          body: 'We do not guarantee that broker elevate, or any content accessible through the website, will always be available or uninterrupted.',
        },
        {
          title: '',
          body: 'You are also responsible for ensuring that all persons who access broker elevate through your internet connection are aware of these terms and other applicable terms and conditions, and that they comply with them.',
        },
        {
          title: '',
          body: 'If you choose, or you are provided with, a user identification code, password or any other piece of information as part of our security procedures, you must treat such information as confidential. You must not disclose it to any third party.',
        },
        {
          title: '',
          body: ' We have the right to disable any user identification code or password, whether chosen by you or allocated by us, at any time, if in our reasonable opinion you have failed to comply with any of the provisions of these terms.',
        },
        {
          title: '',
          body: 'If you know or suspect that anyone other than you knows your user identification code or password, you must promptly notify us at <a href="mailto:info@ignitionnbs.co.uk">info@ignitionnbs.co.uk</a>',
        },
        {
          title: '',
          body: 'You must use broker elevate for lawful purposes, and you may not use broker elevate in any way that breaches any applicable local, national or international law or regulation (including without limitation the Data Protection Act 1998). You agree that your use complies with these terms and you hereby agree to indemnify us against liabilities, costs, expenses, damages and losses (including, but no limited to, any direct, indirect or consequential losses, loss of profit, loss of business opportunity, loss of reputation and all interest, penalties and legal and other professional costs and expenses) suffered or incurred by us arising out of or in connection with your use of broker elevate otherwise in accordance with these terms or applicable law, regulation or otherwise.',
        },
        {
          title: '',
          body: 'The content accessed through use of broker elevate is provided for general information only. It is not intended to amount to advice (of any nature) on which you should rely. You must obtain professional or speciality advice before taking, or refraining from, any action on the basis of content accessed through the use of broker elevate.',
        },
        {
          title: '',
          body: 'We do not take any responsibility for any content accessed through use of broker elevate. Any use or reliance on any content obtained by you through use of broker elevate is at your own risk',
        },
        {
          title: '',
          body: 'We make reasonable commercial efforts to ensure content on broker elevate is up to date and accurate. However, because we get the content from a number of different sources (including information provided by you) we do not endorse, support, represent, warrant or guarantee the completeness, truthfulness, accuracy, or reliability of any content accessed using broker elevate. If you do become aware of any inaccurate or incorrect content accessed or accessible using broker elevate (in particular pertaining to yourself or other individuals) please let us know at <a href="mailto:info@ignitionnbs.co.uk">info@ignitionnbs.co.uk</a> and we will use our reasonable endeavours to investigate your concern and, where appropriate and possible, correct inaccurate data. Content relating to credit scores, in particular, is provided and generated by a third party source, should you have any concerns as regards inaccurate or incorrect content in this regard please also contact us at <a href="mailto:info@ignitionnbs.co.uk">info@ignitionnbs.co.uk</a> and we will use our reasonable endeavours to pass your concern onto the appropriate third party provider. You understand that by using broker elevate, you may be exposed to content that might be inaccurate or deceptive. Under no circumstances (save as required by law) will we be liable in any way for any content accessed, or any loss or damage of any kind incurred as a result of the accessing by you of broker elevate.',
        },
        {
          title: '',
          body: 'You accept responsibility for the selection of and use of broker elevate to achieve your intended results.',
        },
        {
          title: '',
          body: 'Nothing in these Terms excludes or limits our liability for death or personal injury arising from our negligence, or our fraud or fraudulent misrepresentation, or any other liability that cannot be excluded or limited by English law.',
        },
        {
          title: '',
          body: 'brokerelevate.co.uk is provided on an “as is” basis. As such, to the extent permitted by law, no representations, conditions, warranties or other terms of any kind are given in respect of broker elevate, and all statutory (or implied) warranties and conditions are excluded to the fullest extent possible.',
        },
        {
          title: '',
          body:
            'We will not be liable to any user for any loss or damage (whether direct or indirect), whether in contract, tort (including negligence), breach of statutory duty, or otherwise, even if foreseeable, arising under or in connection with:<ul>' +
            '<li>use of, or inability to use, the Services; or</li>' +
            '<li>use of or reliance on any content displayed on the Site or the Application.</li>' +
            '</ul>',
        },
        {
          title: '',
          body:
            'We will not be liable for (without limitation):' +
            '<ul>' +
            '<li>loss of profits, sales, business or revenue;</li>' +
            '<li>business interruption;</li>' +
            '<li>loss of anticipated savings;</li>' +
            '<li>loss of goodwill, opportunity or reputation; or</li>' +
            '<li>any indirect or consequential loss or damage.</li>' +
            '</ul>',
        },
        {
          title: '',
          body: 'We will not be liable for any loss or damage caused by a virus, distributed denial-of-service attack, or other technologically harmful material that may infect your computer equipment, computer programs, data or other proprietary material due to your use of broker elevate.',
        },
        {
          title: '',
          body: 'We assume no responsibility for the content of websites linked on broker elevate. Such links should not be interpreted as endorsement by us of those linked websites. We will not be liable for any loss or damage that may arise from your use of them.',
        },
        {
          title: '',
          body: 'The Services and all related trademarks and service marks (whether registered or unregistered) are proprietary to Ignition or other third parties. We or relevant third parties are the owner or the licensee of all intellectual property rights in broker elevate, and in the material published on it. Those works are protected by copyright laws and treaties around the world. All such rights are reserved.',
        },
        {
          title: '',
          body: 'You shall not attempt to copy, modify, duplicate, create derivative works from, frame, mirror, republish, download, display, transmit, or distribute all or any portion of broker elevate in any form or media or by any means. You will not attempt to reverse compile, disassemble, reverse engineer or otherwise reduce to human-perceivable form all or any part of the Services. You will not access all or any part of broker elevate in order to build a product or service which competes with broker elevate nor will you use broker elevate to provide services to third parties. Use of any automated system or software to extract data from the broker elevate (“screen scraping”) is expressly prohibited.',
        },
        {
          title: '',
          body: 'Our status (and that of any identified contributors) as the authors of content on broker elevate must always be acknowledged. Further, if you use or refer to the results of broker elevate in relation to material that is subsequently published, we request that you credit us as the source of the information with an appropriate attribution.',
        },
        {
          title: '',
          body: 'In the event that you are notified by a third party that that party claims rights in broker elevate or that use of broker elevate infringes any right of that third party, you agree to immediately notify Ignition and, at Ignition/’s request, to immediately cease using broker elevate.',
        },
        {
          title: '',
          body: 'We do not guarantee that broker elevate will be secure or free from bugs or viruses.',
        },
        {
          title: '',
          body: 'You are responsible for configuring your information technology, computer programmes and platform in order to access broker elevate. You should use your own virus protection software.',
        },
        {
          title: '',
          body: 'You must not misuse broker elevate by knowingly introducing viruses, trojans, worms, logic bombs or other material which is malicious or technologically harmful. You must not attempt to gain unauthorised access to broker elevate, the server on which broker elevate is stored or any server, computer or database connected to broker elevate. You must not attack broker elevate via a denial-of-service attack or a distributed denial-of service attack. By breaching this provision, you would commit a criminal offence under the Computer Misuse Act 1990. We will report any such breach to the relevant law enforcement authorities and we will co-operate with those authorities by disclosing your identity to them. In the event of such a breach, your right to use broker elevate will cease immediately.',
        },
        {
          title: '',
          body: 'You may link to our home page, provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it. The website in which you are linking must comply in all respects with applicable laws and generally accepted content standards.',
        },
        {
          title: '',
          body: 'You must not establish a link in such a way as to suggest any form of association, approval or endorsement on our part where none exists.',
        },
        {
          title: '',
          body: 'You must not establish a link to broker elevate in any website that is not owned by you.',
        },
        {
          title: '',
          body: 'broker elevate must not be framed on any other site, nor may you create a link to any part of broker elevate other than the home page.',
        },
        {
          title: '',
          body: 'We reserve the right to withdraw linking permission without notice.',
        },
        {
          title: '',
          body: 'If you wish to make any use of content on broker elevate other than that set out above, please contact <a href="mailto:info@ignitionnbs.co.uk">info@ignitionnbs.co.uk</a>',
        },
        {
          title: '',
          body: 'Where broker elevate contains links to other sites and resources provided by third parties, these links are provided for your information only.',
        },
        {
          title: '',
          body: 'We have no control over the contents of those sites or resources.',
        },
        {
          title: '',
          body: ' This licence and/or your right to access broker elevate may be terminated immediately by Ignition at any time. Upon termination you must cease to use broker elevate and shall completely delete all electronic copies of all or any part of broker elevate resident in your systems or elsewhere.',
        },
        {
          title: '',
          body: ' If you are a consumer, please note that these Terms, their subject matter and their formation, are governed by English law. You and we both agree to that the courts of England and Wales will have non-exclusive jurisdiction. However, if you are a resident of Northern Ireland you may also bring proceedings in Northern Ireland, and if you are resident of Scotland, you may also bring proceedings in Scotland.',
        },
        {
          title: '',
          body: 'If you are a business user, these Terms, their subject matter and their formation (and any non-contractual disputes or claims) are governed by English law. The courts of England and Wales will have exclusive jurisdiction over any claim arising from, or related to, the use of broker elevate, these Terms, their subject matter and their formation (and any non-contractual disputes or claims) although Ignition retains the right to bring proceedings against you for breach of these conditions in your country of residence or any other relevant country.',
        },
        {
          title: '',
          body: 'If you have any concerns about material which appears on broker elevate, please contact us at <a href="mailto:info@ignitionnbs.co.uk">info@ignitionnbs.co.uk</a>',
        },
      ],
    },
    disclaimer: {
      title: 'Agreement for use of Ignition NBS Limited’s brokerelevate.co.uk',
      image: 'home.png',
      meta: {
        title: 'Disclaimer',
        description:
          'All material contained within brokerelevate.co.uk is provided for general information purposes only and on the understanding that none of the content herein constitutes legal or other professional advice.',
      },
      body: [
        {
          title: '',
          body: 'All material contained within brokerelevate.co.uk is provided for general information purposes only and on the understanding that none of the content herein constitutes legal or other professional advice.',
        },
        {
          title: '',
          body: 'Ignition NBS Ltd has made every attempt to ensure that all information contained within this website has been obtained from reliable sources, but all such information is provided "as is", with no guarantee of completeness or accuracy. Any use or reliance on any content obtained by you through use of the brokerelevate.co.uk website is at your own risk.',
        },
        {
          title: '',
          body: 'Ignition NBS Ltd does not warranty or make any representations concerning the accuracy, likely results, or reliability of the use of the materials contained within this website or otherwise relating to such materials or on any sites linked to this site.',
        },
        {
          title: '',
          body: ' In no event shall Ignition NBS Ltd or its suppliers be liable for any damages (including, without limitation, loss of data; loss of profits, sales, business or revenue; business interruption; loss of goodwill, opportunity or reputation) arising out of the use or inability to use the materials on the brokerelevate.co.uk website.',
        },
        {
          title: '',
          body: 'You accept responsibility for the selection of and use of brokerelevate.co.uk to achieve your intended results.',
        },
      ],
    },
    cookieNotice: {
      title: 'Cookie Notice',
      body: [
        {
          title: '',
          body: 'We use cookies on Broker Elevate to keep your information safe, they also help us understand how you use Broker Elevate and help personalise your experience.',
          button: 'OK',
        },
      ],
    },
    moduleComplete: {
      title: 'Module Complete!',
      body: [
        {
          title: '',
          body: "<strong>Congratulations</strong><br/> You've completed this module and a new card has been added to your collection!",
        },
        {
          title: '',
          body: 'Got feedback or ideas? Please share them using the feedback form below the module content.',
        },
      ],
      button: 'Close',
    },
    dealstatus: {
      title: 'Your 2022 growth deal statement summary is enclosed for you to review.',
      image: 'dealstatus.png',
      meta: {
        title: 'Deal Status',
        description: 'Growth deal statement summary is enclosed for you to review',
      },
      body: [
        {
          title: 'These monthly statements provide you with an overview of:',
          body: '"Qualifying" GWP written Year to Date',
        },
      ],
    },
  },
  files: [],
};

export const BrokerelevateCoUk = {
  tenant,
};
