<template>
  <div class="manual-complete" v-if="!this.$user.findInCollection('module', this.module.id)">
    <div v-if="this.showPanel" >
      <div class="row">
        <div class="col  text-center">
          <h1 style="color:#fff">Mark Module Complete</h1>
        </div>
      </div>
      <div class="row">
        <div class="col text-center">
          <p style="color:#fff">Once you have read, and understood, this module please click the button below to mark it as completed and add it to you collection.</p>
        </div>
      </div>
      <div class="row">
        <div class="col text-center">
          <button type="button" class="btn btn-primary" @click="markModuleComplete">
            Mark Module Complete
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['module'],
  data() {
    return {
      showPanel: true,
    };
  },
  methods: {
    markModuleComplete() {
      if (this.module.status.state !== 'finished') {
        this.module.status.state = 'finished';
        this.showPanel = false;
        this.$eventBus.$emit('set-module-finished');
      }
    },
  },
};
</script>
