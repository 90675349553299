const module = [
  {
    id: 'f88f57d8-2798-400d-92e7-393ffb9d1fa3',
    slug: 'top-tips-for-challenging-conversations-in-a-virtual-setting',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: 'f88f57d8-2798-400d-92e7-393ffb9d1fa3|d1c90dbc-6e2e-4e77-90c3-319a5bc220a6',
    template: 'pdf-template',
    title: 'Top Tips For Challenging Conversations In A Virtual Setting',
    description:
      'Read our top tips for having challenging conversations.',
    completedDescription: 'TODO',
    supplier: 'Ignition NBS',
    categoryId: 'd1c90dbc-6e2e-4e77-90c3-319a5bc220a6',
    questionnaireId: '',
    contents: {
      id: '4a8f587b-ebc7-4066-8f9d-f7c73fda0dae',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'It can be difficult to discuss sensitive issues when your team are remote. For practical guidance, read our top 10 tips for challenging conversations in a virtual setting.',
      heroImage: false,
      media: [
        {
          type: 'document',
          subType: 'pdf',
          title: 'Top Tips For Challenging Conversations In A Virtual Setting',
          button: '',
          fileSize: 0,
          url: 'top-tips-for-challenging-conversations-in-a-virtual-setting.pdf',
          caption: {
            title: '',
            description:
              ''
          }
        },
        {
          type: 'file',
          subType: 'pdf',
          title: 'Top Tips For Challenging Conversations In A Virtual Setting',
          button: 'Download File',
          fileSize: '89948',
          url: 'top-tips-for-challenging-conversations-in-a-virtual-setting.pdf',
          caption: {
            title: '',
            description: ''
          },
          axaUrl: ''
        }
      ]
    },
    progress: {
      value: 1,
      maximum: 1
    },
    statistics: {
      level: 0,
      timeEstimate: '30'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['management','skills']
  },
];

export const TopTipsForChallengingConversationsInAVirtualSetting = {
  module
};