<template>
  <div class="pdf-section">
    <div class="row">
      <div class="col">
        <div class="PdfContainer">
          <iframe :src="this.getMediaPath('module', module.id, module.contents.media[0].url+'#toolbar=1')" width="100%" height="1280px"></iframe>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col caption">
        <h6 v-if="module.contents.media[0].caption.title != ''">
          {{ module.contents.media[0].caption.title }}
        </h6>
        <div v-html="module.contents.media[0].caption.description"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { MediaMixin } from '@/mixins/media.mixin';
import { MetricsMixin } from '@/mixins/metrics.mixin';

export default {
  mixins: [MediaMixin, MetricsMixin],
  props: ['module'],
  data() {
    return {
      player: null,
    };
  },
  methods: {
    // hasModuleStarted(data) {
    //   if (this.module.status.state != 'finished') {
    //     this.$eventBus.$emit('set-module-started');
    //   }
    // },
    // hasModuleFinished(data) {
    //   if (
    //     data.percent > 0.95 &&
    //     this.module.status.state != 'finished' &&
    //     this.module.questionnaireId === ''
    //   ) {
    //     this.module.status.state = 'finished';
    //     this.player.off('timeupdate');
    //     this.$eventBus.$emit('set-module-finished');
    //     this.$eventBus.$emit('show-module-complete-message');
    //   }
    // },
  },
  mounted() {
    // var iframe = document.querySelector('iframe');
    
  },
};
</script>

<style>
iframe {
        border: none;
    }
</style>