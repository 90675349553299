const module = [
  {
    id: 'e91025db-f2ce-4560-b07d-a2f50dd1a0fe',
    slug: 'tips-for-surveying-your-team',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: 'e91025db-f2ce-4560-b07d-a2f50dd1a0fe|d1c90dbc-6e2e-4e77-90c3-319a5bc220a6',
    template: 'pdf-template',
    title: 'Tips For Surveying Your Team',
    description:
      'Read our article to help you position the survey and how to use the resulting data.',
    completedDescription: 'TODO',
    supplier: 'Ignition NBS',
    categoryId: 'd1c90dbc-6e2e-4e77-90c3-319a5bc220a6',
    questionnaireId: '',
    contents: {
      id: '75aa8530-e300-41eb-a114-028fc64bdf89',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'Seeking quality feedback from your employees can help to inform your decision-making. If you are considering using a staff survey, we have compiled some tips on how to position the survey and use the resulting data, along with some sample questions.',
      heroImage: false,
      media: [
        {
          type: 'document',
          subType: 'pdf',
          title: 'Tips For Surveying Your Team',
          button: '',
          fileSize: 0,
          url: 'tips-for-surveying-your-team.pdf',
          caption: {
            title: '',
            description:
              '',
          }
        },
        {
          type: 'file',
          subType: 'pdf',
          title: 'Tips For Surveying Your Team',
          button: 'Download File',
          fileSize: '91994',
          url: 'tips-for-surveying-your-team.pdf',
          caption: {
            title: '',
            description: ''
          },
          axaUrl: ''
        }
      ]
    },
    progress: {
      value: 1,
      maximum: 1
    },
    statistics: {
      level: 0,
      timeEstimate: '30'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['management','leadership']
  },
];

export const TipsForSurveyingYourTeam = {
  module
};