const module = [
  {
    id: '311cf78d-e9cf-4ef8-9ae6-374e56ebf758',
    slug: 'whats-affecting-productivity-and-motivation',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: '311cf78d-e9cf-4ef8-9ae6-374e56ebf758|d1c90dbc-6e2e-4e77-90c3-319a5bc220a6',
    template: 'pdf-template',
    title: "What's Affecting Productivity And Motivation",
    description:
      "This article covers the importance of understanding the factors affecting your team's productivity",
    completedDescription: 'TODO',
    supplier: 'Ignition NBS',
    categoryId: 'd1c90dbc-6e2e-4e77-90c3-319a5bc220a6',
    questionnaireId: '',
    contents: {
      id: '238faaa8-b682-4953-b43e-f7bb66e5ffcb',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        "This article covers the importance of understanding the factors affecting your team's productivity and provides tips and guidance to help you engage and motivate your team, mitigate uncertainty and provide employee recognition." +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>Factors which may be affecting motivation</li>' +
        '<li>Keeping everyone connected</li>' +
        '<li>Don’t micro manage</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'document',
          subType: 'pdf',
          title: "What's Affecting Productivity And Motivation",
          button: '',
          fileSize: 0,
          url: 'whats-affecting-productivity-and-motivation.pdf',
          caption: {
            title: '',
            description:
              ''
          }
        },
        {
          type: 'file',
          subType: 'pdf',
          title: "What's Affecting Productivity And Motivation",
          button: 'Download File',
          fileSize: '280039',
          url: 'whats-affecting-productivity-and-motivation.pdf',
          caption: {
            title: '',
            description: ''
          },
          axaUrl: ''
        }
      ]
    },
    progress: {
      value: 1,
      maximum: 1
    },
    statistics: {
      level: 0,
      timeEstimate: '30'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['management','inclusion','wellbeing']
  },
];

export const WhatsAffectingProductivityAndMotivation = {
  module
};