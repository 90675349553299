// Looks like a repeat of remote-working-checklist so excluded 
const module = [
  {
    id: '75873b78-0ebb-45a3-9d66-b6489f8e516a',
    slug: 'remote-onboarding-checklist',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: '75873b78-0ebb-45a3-9d66-b6489f8e516a|d1c90dbc-6e2e-4e77-90c3-319a5bc220a6',
    template: 'pdf-template',
    title: 'Remote Onboarding Checklist',
    description:
      'An remote onboarding checklist provides helpful reminders for best practice at each stage of the remote onboarding process',
    completedDescription: 'TODO',
    supplier: 'Ignition NBS',
    categoryId: 'd1c90dbc-6e2e-4e77-90c3-319a5bc220a6',
    questionnaireId: '',
    contents: {
      id: '952b9c9a-47a2-4c2c-9b96-85c1d118c1c9',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'Our remote onboarding checklist provides helpful reminders for best practice at each stage of the remote onboarding process, from ensuring IT equipment is ready to booking induction sessions with key stakeholders and holding a virtual team meeting.',
      heroImage: false,
      media: [
        {
          type: 'document',
          subType: 'pdf',
          title: 'Remote Onboarding Checklist',
          button: '',
          fileSize: 0,
          url: 'remote-onboarding-checklist.pdf',
          caption: {
            title: '',
            description:
              ''
          }
        },
        {
          type: 'file',
          subType: 'pdf',
          title: 'Remote Onboarding Checklist',
          button: 'Download File',
          fileSize: '120814',
          url: 'remote-onboarding-checklist.pdf',
          caption: {
            title: '',
            description: ''
          },
          axaUrl: ''
        }
      ]
    },
    progress: {
      value: 1,
      maximum: 1
    },
    statistics: {
      level: 0,
      timeEstimate: '30'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['skills','leadership','wellbeing']
  },
];

export const RemoteOnboardingChecklist = {
  module
};