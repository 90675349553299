import { AppConfig } from '@/config/config';
import Axios from '@/packages/axios/Axios';
import eventBus from '@/packages/events/eventBus';
import localStorage from '@/packages/storage/LocalStorage';
import user from '@/packages/user/User';
import 'babel-polyfill';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Es6Promise from 'es6-promise';
import { ValidationObserver, ValidationProvider } from 'vee-validate/dist/vee-validate.full';
import Vue from 'vue';
import VueLazyload from 'vue-lazyload';
import browserDetect from 'vue-browser-detect-plugin';
import VueGtag from 'vue-gtag';
import App from './App.vue';
import router from './router';
import VueMeta from 'vue-meta';
import VueSanitize from 'vue-sanitize';
import VueCookies from 'vue-cookies';

import { Courses } from '@/content/courses';
import { ModuleContent } from '@/content/modules';

Vue.prototype.$appConfig = AppConfig;

// Get tenant definitions
import { TenantContent } from '@/content/tenants/tenants';
var tenant = [];

for (let i = 0; i < TenantContent.tenants.length; i++) {
  if (TenantContent.tenants[i].id == AppConfig.tenant.id) {
    tenant = TenantContent.tenants[i];
    Vue.prototype.$tenant = TenantContent.tenants[i];
    Vue.prototype.$content = TenantContent.tenants[i].content;

    // loop through this tenants curriculum and populate it's courses & modules with detail
    var rawCurriculum = Vue.prototype.$tenant.curriculum;
    var populatedCurriculum = {
      courses: [],
      tags: []
    };

    for (let j = 0; j < rawCurriculum.courses.length; j++) {
      // populate course details
      var populatedCourse = getCourseDetail(rawCurriculum.courses[j].id);

      // populate course's module details
      populatedCourse.modules = [];
      for (let k = 0; k < rawCurriculum.courses[j].modules.length; k++) {
        var populatedModule = getModuleDetails(rawCurriculum.courses[j].modules[k].id);
        if (populatedModule) {
          if (populatedModule.tags === undefined) {
            // add tags property if it doesn't exitist on module, as it's nor required as part of definition
            populatedModule.tags = [];
          }
          // consolidate module and tenant's curriculum module tags
          if (rawCurriculum.courses[j].modules[k].tags !== undefined) {
            // Tag Additions
            if (rawCurriculum.courses[j].modules[k].tags.additions !== undefined) {
              // add any additional curriculum module tags (additions)
              for (let l = 0; l < rawCurriculum.courses[j].modules[k].tags.additions.length; l++) {
                if (
                  doesTagAlreadyExist(
                    populatedModule.tags,
                    rawCurriculum.courses[j].modules[k].tags.additions[l]
                  ) === -1
                ) {
                  // tag doesn't exist so add it
                  populatedModule.tags.push(rawCurriculum.courses[j].modules[k].tags.additions[l]);
                }
              }
            }
            // Tag Removals
            if (rawCurriculum.courses[j].modules[k].tags.removals !== undefined) {
              // remove any curriculum module tags from populated module  (removals)
              for (let l = 0; l < rawCurriculum.courses[j].modules[k].tags.removals.length; l++) {
                var positionInArray = doesTagAlreadyExist(
                  populatedModule.tags,
                  rawCurriculum.courses[j].modules[k].tags.removals[l]
                );

                if (positionInArray > -1) {
                  // tag exists so remove it
                  populatedModule.tags.splice(positionInArray, 1);
                }
              }
            }
          }
        }
        // change specific tag's position in array
        populatedModule = changeTagPosition(populatedModule, 'accredited', 0);
        populatedModule = changeTagPosition(populatedModule, 'new', 0);
        populatedModule = changeTagPosition(populatedModule, 'exclusive', 0);

        // add accreditation object if applicable
        if (rawCurriculum.courses[j].modules[k].accreditation !== undefined) {
          populatedModule.accreditation = rawCurriculum.courses[j].modules[k].accreditation;
        }

        // add questionnaire object if applicable
        if (rawCurriculum.courses[j].modules[k].questionnaireOverride !== undefined) {
          populatedModule.questionnaireOverride =
            rawCurriculum.courses[j].modules[k].questionnaireOverride;
        }

        populatedCourse.modules.push(populatedModule);
      }
      populatedCurriculum.courses.push(populatedCourse);
    }

    ///////////////////////////////////////////////////
    // TODO: Guus - add filter code HERE
    var filteredPopulatedCurriculum = populatedCurriculum;
    ///////////////////////////////////////////////////

    // replace tenants curriculum with  populated curriculum
    Vue.prototype.$tenant.curriculum = filteredPopulatedCurriculum;

    if (TenantContent.tenants[i].auth.relatedTenants != undefined) {
      Axios.defaults.headers.common['X-Related-Tenants'] =
        TenantContent.tenants[i].auth.relatedTenants;
    }
  }
}

Vue.use(VueMeta);
Vue.use(VueSanitize);

Vue.use(
  VueGtag,
  {
    config: { id: tenant.googleAnalytics.id },
    appName: tenant.googleAnalytics.appName
  },
  router
);
Vue.use(browserDetect);
Vue.use(eventBus);
Vue.use(VueCookies);
Vue.use(localStorage);
Vue.use(user);
Vue.use(VueLazyload);

Es6Promise.polyfill();
Vue.component('validation-observer', ValidationObserver);
Vue.component('validation-provider', ValidationProvider);
Vue.prototype.$isLoggedIn = () => {
  return !(
    Axios.defaults.headers.common.Authorization === undefined ||
    Axios.defaults.headers.common.Authorization === '' ||
    Axios.defaults.headers.common.Authorization === null
  );
};

Vue.prototype.$roles = () => {
  return window.localStorage.getItem('roles');
};

window.setRolesInformation = roles => {
  window.localStorage.setItem('roles', roles || []);
};

const token = window.localStorage.getItem('authz');
if (token) {
  Axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
} else {
  window.setRolesInformation([]);
}

new Vue({
  router,
  render: h => h(App)
}).$mount('#app');

function getCourseDetail(courseId) {
  for (let i = 0; i < Courses.courses.length; i++) {
    if (Courses.courses[i].id == courseId) {
      return Courses.courses[i];
    }
  }
}

function getModuleDetails(moduleId) {
  for (let i = 0; i < ModuleContent.modules.length; i++) {
    if (ModuleContent.modules[i].id == moduleId) {
      return ModuleContent.modules[i];
    }
  }

  return false;
}

function doesTagAlreadyExist(existingTags, newTag) {
  if (existingTags !== undefined) {
    return existingTags.indexOf(newTag);
  }

  return false;
}

function changeTagPosition(module, tag, newPosition) {
  if (module.tags !== undefined) {
    if (module.tags.indexOf(tag) > -1) {
      // remove the tag from array
      module.tags.splice(module.tags.indexOf(tag));
      // add the tag back in the new position
      module.tags.splice(newPosition, 0, tag);
    }
  }

  return module;
}
