const module = [
  {
    id: 'aca90647-0900-4d89-87f5-3c032d163aee',
    slug: 'setting-expectations',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: 'aca90647-0900-4d89-87f5-3c032d163aee|d1c90dbc-6e2e-4e77-90c3-319a5bc220a6',
    template: 'pdf-template',
    title: 'Setting Expectations',
    description:
      'This article covers how to set clear expectations, measure productivity and ensure effective communication with your team when working remotely.',
    completedDescription: 'TODO',
    supplier: 'Ignition NBS',
    categoryId: 'd1c90dbc-6e2e-4e77-90c3-319a5bc220a6',
    questionnaireId: '',
    contents: {
      id: 'a9075a10-82f6-4979-83b3-1d66a8e576d4',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'This article highlights the importance of communicating and setting clear expectations to ensure productivity is maintained whilst working in a hybrid environment.' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>How to set clear expectations</li>' +
        '<li>How to measure productivity </li>' +
        '<li>Effective communication</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'document',
          subType: 'pdf',
          title: 'Setting Expectations',
          button: '',
          fileSize: 0,
          url: 'setting-expectations.pdf',
          caption: {
            title: '',
            description:
              ''
          }
        },
        {
          type: 'file',
          subType: 'pdf',
          title: 'Setting Expectations',
          button: 'Download File',
          fileSize: '280039',
          url: 'setting-expectations.pdf',
          caption: {
            title: '',
            description: ''
          },
          axaUrl: ''
        }
      ]
    },
    progress: {
      value: 1,
      maximum: 1
    },
    statistics: {
      level: 0,
      timeEstimate: '30'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['management','leadership']
  },
];

export const SettingExpectations = {
  module
};