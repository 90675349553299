const module = [
  {
    id: '42ec7649-2f8b-4cf2-994e-0e32e36ec905',
    slug: 'protecting-your-top-talent-from-burnout',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: '42ec7649-2f8b-4cf2-994e-0e32e36ec905|d1c90dbc-6e2e-4e77-90c3-319a5bc220a6',
    template: 'pdf-template',
    title: 'Protecting Your Top Talent From Burnout',
    description:
      'This article provides you with the knowledge required to recognise the warning signs of burnout.',
    completedDescription: 'TODO',
    supplier: 'Ignition NBS',
    categoryId: 'd1c90dbc-6e2e-4e77-90c3-319a5bc220a6',
    questionnaireId: '',
    contents: {
      id: 'ba8d7b10-15c0-4158-9ca2-16af6b13462b',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'This article provides you with the knowledge required to recognise the warning signs of burnout, along with resources to help you provide support to employees who may be struggling.',
      heroImage: false,
      media: [
        {
          type: 'document',
          subType: 'pdf',
          title: 'Protecting Your Top Talent From Burnout',
          button: '',
          fileSize: 0,
          url: 'protecting-your-top-talent-from-burnout.pdf',
          caption: {
            title: '',
            description:
              ''
          }
        },
        {
          type: 'file',
          subType: 'pdf',
          title: 'Protecting Your Top Talent From Burnout',
          button: 'Download File',
          fileSize: '104895',
          url: 'protecting-your-top-talent-from-burnout.pdf',
          caption: {
            title: '',
            description: ''
          },
          axaUrl: ''
        }
      ]
    },
    progress: {
      value: 1,
      maximum: 1
    },
    statistics: {
      level: 0,
      timeEstimate: '30'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['leadership','wellbeing']
  },
];

export const ProtectingYourTopTalentFromBurnout = {
  module
};