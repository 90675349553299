const module = [
  {
    id: 'a387d6a6-0842-4b2f-adbf-0de4c4afaaec',
    slug: 'remote-working-checklist',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: 'a387d6a6-0842-4b2f-adbf-0de4c4afaaec|d1c90dbc-6e2e-4e77-90c3-319a5bc220a6',
    template: 'pdf-template',
    title: 'Remote Working Checklist',
    description:
      'A practical checklist to support you in the onboarding process.',
    completedDescription: 'TODO',
    supplier: 'Ignition NBS',
    categoryId: 'd1c90dbc-6e2e-4e77-90c3-319a5bc220a6',
    questionnaireId: '',
    contents: {
      id: '2aa0a022-5c4b-4bff-afcd-d6835d0c548d',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'Our remote onboarding checklist provides helpful reminders for best practice at each stage of the remote onboarding process, from ensuring IT equipment is ready to booking induction sessions with key stakeholders and holding a virtual team meeting.' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>Offer/preboarding</li>' +
        '<li>Induction planning</li>' +
        '<li>Homeworking</li>' +
        '<li>First day</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'document',
          subType: 'pdf',
          title: 'Remote Working Checklist',
          button: '',
          fileSize: 0,
          url: 'remote-working-checklist.pdf',
          caption: {
            title: '',
            description:
              ''
          }
        },
        {
          type: 'file',
          subType: 'pdf',
          title: 'Remote Working Checklist',
          button: 'Download File',
          fileSize: '120814',
          url: 'remote-working-checklist.pdf',
          caption: {
            title: '',
            description: ''
          },
          axaUrl: ''
        }
      ]
    },
    progress: {
      value: 1,
      maximum: 1
    },
    statistics: {
      level: 0,
      timeEstimate: '30'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['skills','leadership','wellbeing']
  },
];

export const RemoteWorkingChecklist = {
  module
};