const module = [
  {
    id: '4350b447-2ec5-4f27-8e9d-74bba7727305',
    slug: 'managers-guide-to-supporting-mental-health-and-wellbeing-in-the-workplace',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: '4350b447-2ec5-4f27-8e9d-74bba7727305|d1c90dbc-6e2e-4e77-90c3-319a5bc220a6',
    template: 'pdf-template',
    title: "Manager's guide to supporting workplace mental health & wellbeing",
    description:
      "This article is designed to help you support your team's mental wellbeing and more effectively look after your own mental health.",
    completedDescription: 'TODO',
    supplier: 'Ignition NBS',
    categoryId: 'd1c90dbc-6e2e-4e77-90c3-319a5bc220a6',
    questionnaireId: '',
    contents: {
      id: '17458b48-7464-4e0c-bc6a-ffb75335dc17',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        "This article is designed to help you support your team's mental wellbeing and more effectively look after your own mental health. Learn how to recognise the symptoms of common mental health conditions, manage an employee who may be living with a mental health condition and improve wellbeing within your team.",
      heroImage: false,
      media: [
        {
          type: 'document',
          subType: 'pdf',
          title: "Manager's guide to supporting mental health and wellbeing in the workplace",
          button: '',
          fileSize: 0,
          url: 'managers-guide-to-supporting-mental-health-and-wellbeing-in-the-workplace.pdf',
          caption: {
            title: '',
            description:
              ''
          }
        },
        {
          type: 'file',
          subType: 'pdf',
          title: "Manager's guide to supporting mental health and wellbeing in the workplace",
          button: 'Download File',
          fileSize: '15094238',
          url: 'managers-guide-to-supporting-mental-health-and-wellbeing-in-the-workplace.pdf',
          caption: {
            title: '',
            description: ''
          },
          axaUrl: ''
        }
      ]
    },
    progress: {
      value: 1,
      maximum: 1
    },
    statistics: {
      level: 1,
      timeEstimate: '45'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['management','leadership','wellbeing']
  },
];

export const ManagersGuideToSupportingMentalHealthAndWellbeingInTheWorkplace = {
  module
};