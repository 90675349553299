const module = [
  {
    id: '42c18d3f-56dc-4a26-8b6d-b3e55f2fb44c',
    slug: 'how-to-organise-online-events',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: '42c18d3f-56dc-4a26-8b6d-b3e55f2fb44c|10c10b32-fca4-497c-8694-e9f3e81fab20',
    template: 'pdf-template',
    title: 'How To Organise Online Events',
    description:
      "This ‘How to’ guide takes a look at how you could take your engagement online.",
    completedDescription: 'TODO',
    supplier: 'Ignition NBS',
    categoryId: '10c10b32-fca4-497c-8694-e9f3e81fab20',
    questionnaireId: '',
    contents: {
      id: '410fa718-a642-441c-9e89-694429dbbc46',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'In this guide you’ll understand all the benefits and steps you need to consider making your online events a success.' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>Benefits of online events.</li>' +
        '<li>Things to consider when planning your online event.</li>' +
        '<li>How to host your event online.</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'document',
          subType: 'pdf',
          title: 'How To Organise Online Events',
          button: '',
          fileSize: 0,
          url: 'how-to-organise-online-events.pdf',
          caption: {
            title: '',
            description:
              ''
          }
        },
        {
          type: 'file',
          subType: 'pdf',
          title: 'How To Organise Online Events',
          button: 'Download File',
          fileSize: '399347',
          url: 'how-to-organise-online-events.pdf',
          caption: {
            title: '',
            description: ''
          },
          axaUrl: ''
        }
      ]
    },
    progress: {
      value: 1,
      maximum: 1
    },
    statistics: {
      level: 0,
      timeEstimate: '30'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['marketing','sales','skills']
  },
];

export const HowToOrganiseOnlineEvents = {
  module
};