// Impactful communication
import { VirtualMeetingsSettingYourselfUpForSuccess } from '@/content/courses/itd-course-impactful-communication-in-a-virtual-trading-environment/virtual-meetings-setting-yourself-up-for-success/definition.js';
import { ImpactfulCommunicationsAndRapport } from '@/content/courses/itd-course-impactful-communication-in-a-virtual-trading-environment/impactful-communications-and-rapport/definition.js';

// Manufacturing
import { ManufacturingPropertyInsuranceIssuesAndImplications } from '@/content/courses/itd-course-technical-manufacturing/manufacturing-property-insurance-issues-and-implications/definition.js';
import { ManufacturingBusinessInterruptionIssuesAndImplicationsPart_1 } from '@/content/courses/itd-course-technical-manufacturing/manufacturing-business-interruption-issues-and-implications-part-1/definition.js';
import { ManufacturingBusinessInterruptionIssuesAndImplicationsPart_2 } from '@/content/courses/itd-course-technical-manufacturing/manufacturing-business-interruption-issues-and-implications-part-2/definition.js';
import { ManufacturingLiabilityAndMotorInsuranceIssuesAndImplications } from '@/content/courses/itd-course-technical-manufacturing/manufacturing-liability-and-motor-insurance-issues-and-implications/definition.js';

// Marketing
import { IntroductionToSocialMedia } from '@/content/courses/itd-course-ignition-marketing/introduction-to-social-media/definition.js';
import { IntroductionToLinkedin } from '@/content/courses/itd-course-ignition-marketing/introduction-to-linkedin/definition.js';
import { HowToCreateALinkedInProfile } from '@/content/courses/itd-course-ignition-marketing/how-to-create-a-linkedIn-profile/definition.js';

// Sales in a changing environment
import { SalesProcessOverview } from '@/content/courses/itd-course-sales-in-a-changing-environment/sales-process-overview/definition.js';
import { AdaptingToAHardeningMarketAndStrategicSelling } from '@/content/courses/itd-course-sales-in-a-changing-environment/adapting-to-a-hardening-market-and-strategic-selling/definition.js';
import { BecomingASolutionsProvider } from '@/content/courses/itd-course-sales-in-a-changing-environment/becoming-a-solutions-provider/definition.js';
import { SocialSellingAndVirtualNetworking } from '@/content/courses/itd-course-sales-in-a-changing-environment/social-selling-and-virtual-networking/definition.js';
import { EffectiveCommunicationInAVirtualWorld } from '@/content/courses/itd-course-sales-in-a-changing-environment/effective-communication-in-a-virtual-world/definition.js';
import { VirtualMeetings } from '@/content/courses/itd-course-sales-in-a-changing-environment/virtual-meetings/definition.js';

// Thriving in a hard market
import { TheDynamicsOfTheInsuranceMarketCycle } from '@/content/courses/itd-course-thriving-in-the-hard-market/the-dynamics-of-the-insurance-market-cycle/definition.js';
import { TradingInAHardMarket } from '@/content/courses/itd-course-thriving-in-the-hard-market/trading-in-a-hard-market/definition.js';
import { YourPersonalEffectivenessInAHardMarket } from '@/content/courses/itd-course-thriving-in-the-hard-market/your-personal-effectiveness-in-a-hard-market/definition.js';

// Work Burnout
import { WhatIsWorkBurnout } from '@/content/courses/itd-course-work-burnout/what-is-work-burnout/definition.js';
import { StagesOfBurnout } from '@/content/courses/itd-course-work-burnout/stages-of-burnout/definition.js';
import { HowToTalkToYourBossAboutBurnout } from '@/content/courses/itd-course-work-burnout/how-to-talk-to-your-boss-about-burnout/definition.js';
import { MythsAboutBurnout } from '@/content/courses/itd-course-work-burnout/myths-about-burnout/definition.js';

// Construction
import { ConstructionMarketSegmentationAndKeyIssues } from '@/content/courses/itd-course-technical-construction/construction-market-segmentation-and-key-issues/definition.js';
import { ConstructionLiabilityIssuesInTheConstructionSectorPart_1 } from '@/content/courses/itd-course-technical-construction/construction-liability-issues-in-the-construction-sector-part-1/definition.js';
import { ConstructionLiabilityIssuesInTheConstructionSectorPart_2 } from '@/content/courses/itd-course-technical-construction/construction-liability-issues-in-the-construction-sector-part-2/definition.js';
import { ConstructionContractorsAllRisksErectionAllRisksAndBiIssues } from '@/content/courses/itd-course-technical-construction/construction-contractors-all-risks-erection-all-risks-and-bi-issues/definition.js';
import { ConstructionOtherCoverOptionsForTheConstructionIndustry } from '@/content/courses/itd-course-technical-construction/construction-other-cover-options-for-the-construction-industry/definition.js';

// Technical Real Estate
import { RealEstateMarketSegmentationAndKeyIssues } from '@/content/courses/itd-course-technical-real-estate/real-estate-market-segmentation-and-key-issues/definition.js';
import { RealEstateConstructionLeasesAndLendersInTheRealEstateSector } from '@/content/courses/itd-course-technical-real-estate/real-estate-construction-leases-and-lenders-in-the-real-estate-sector/definition.js';
import { RealEstatePropertyInsuranceKeyCoverIssuesInPropertyOwnersPolicies } from '@/content/courses/itd-course-technical-real-estate/real-estate-property-insurance-key-cover-issues-in-property-owners-policies/definition.js';
import { RealEstateIncomeProtectionLossOfRentAndAlternativeAccommodation } from '@/content/courses/itd-course-technical-real-estate/real-estate-income-protection-loss-of-rent-and-alternative-accommodation/definition.js';
import { RealEstateAdditionalPoliciesToConsiderForTheRealEstateSector } from '@/content/courses/itd-course-technical-real-estate/real-estate-additional-policies-to-consider-for-the-real-estate-sector/definition.js';

// Actus Employee Resilience and wellbeing
import { ActusEmployeeeWellbeingAndResilience } from '@/content/courses/actus-employeee-wellbeing-and-resilience/module-1-resilience-and-wellbeing/definition.js';

// RRadar People Management Series
import { ManagingSicknessAbsence } from '@/content/courses/rradar-people-management-series/managing-sickness-absence/definition.js';
import { PerformanceManagement } from '@/content/courses/rradar-people-management-series/performance-management/definition.js';
import { Discrimination } from '@/content/courses/rradar-people-management-series/discrimination/definition.js';

// Your Behaviours Matter
import { YourBehavioursMatter } from '@/content/courses/itd-course-your-behaviour-matters/your-behaviours-matter/definition.js';
import { FlexingYourApproachToDriveEngagement } from '@/content/courses/itd-course-your-behaviour-matters/flexing-your-approach-to-drive-engagement/definition.js';
import { MotivatingYourTeamForImprovedPerformance } from '@/content/courses/itd-course-your-behaviour-matters/motivating-your-team-for-improved-performance/definition.js';

//  Actus Change Management Series
import { ManagingChange } from '@/content/courses/actus-change-management-series/managing-change/definition.js';
import { DealingWithChange } from '@/content/courses/actus-change-management-series/dealing-with-change/definition.js';
import { LeadingChange } from '@/content/courses/actus-change-management-series/leading-change/definition.js';

// Actus Employeee series
import { AgreeingSmarterObjectives } from '@/content/courses/actus-employee-series/agreeing-smarter-objectives/definition.js';
import { Effective121sAndAppraisals } from '@/content/courses/actus-employee-series/effective-1-2-1s-and-appraisals/definition.js';
import { KeyCommunicationSkillsAndSelfAwareness } from '@/content/courses/actus-employee-series/key-communication-skills-and-self-awareness/definition.js';

// Actus Hybrid Management
import { KeyCommunicationSkillsSelfAwareness } from '@/content/courses/actus-hybrid-management/key-communication-skills-and-self-awareness/definition.js';
import { SettingSmarterObjectivesRemotely } from '@/content/courses/actus-hybrid-management/setting-smarter-objectives-remotely/definition.js';
import { Effective121sAndAppraisalsInAHybridWorld } from '@/content/courses/actus-hybrid-management/effective-1-2-1s-and-appraisals-in-a-hybrid-world/definition.js';
import { DeliverFeedbackForPositiveOutcomes } from '@/content/courses/actus-hybrid-management/deliver-feedback-for-positive-outcomes/definition.js';
import { ManageUnderperformanceInAHybridSetting } from '@/content/courses/actus-hybrid-management/manage-underperformance/definition.js';

// AXA Health	Nurturing your mental health
import { NurturingYourMentalHealth } from '@/content/courses/axa-nurturing-your-mental-health/nurturing-your-mental-health/definition.js';

// Matthews keeping your high performers performing
import { KeepingYourHighPerformersPerforming } from '@/content/courses/matthews-keeping-your-high-performers-performing/keeping-your-high-performers-performing/definition.js';
import { InspiringHighPerformanceFromYourTeamWhenThePressureIsOn } from '@/content/courses/matthews-keeping-your-high-performers-performing/inspiring-high-performance-from-your-team-when-the-pressure-is-on/definition.js';
import { TheExtraOnePerformanceEdge } from '@/content/courses/matthews-keeping-your-high-performers-performing/the-extra-1-percent-performance-edge/definition.js';

// AXA Motor Fleet Electric vehicles charging ahead
import { ElectricVehiclesTechnicalTraining } from '@/content/courses/axa-motor-fleet/electric-vehicles-charging-ahead/definition.js';

// NTA introduction-to-the-sales-process
import { IntroductionToTheSalesProcess } from '@/content/courses/nta-introduction-to-sales/introduction-to-the-sales-process/definition.js';
import { IntroductionToSalesProspectingOne } from '@/content/courses/nta-introduction-to-sales/prospecting-one/definition.js';
import { IntroductionToSalesProspectingTwo } from '@/content/courses/nta-introduction-to-sales/prospecting-two/definition.js';
import { IntroductionToSalesConnectingOne } from '@/content/courses/nta-introduction-to-sales/connecting-one/definition.js';
import { IntroductionToSalesConnectingTwo } from '@/content/courses/nta-introduction-to-sales/connecting-two/definition.js';
import { IntroductionToSalesNeedsDiscoveryOne } from '@/content/courses/nta-introduction-to-sales/needs-discovery-one/definition.js';
import { IntroductionToSalesNeedsDiscoveryTwo } from '@/content/courses/nta-introduction-to-sales/needs-discovery-two/definition.js';
import { IntroductionToSalesSolutionProposal } from '@/content/courses/nta-introduction-to-sales/solution-proposal/definition.js';
import { IntroductionToSalesObjectionHandling } from '@/content/courses/nta-introduction-to-sales/objection-handling/definition.js';
import { IntroductionToSalesClosing } from '@/content/courses/nta-introduction-to-sales/closing/definition.js';

// NTA nta-introduction-to-professional-indemnity-insurance
import { HowLiabilityArises } from '@/content/courses/nta-introduction-to-professional-indemnity-insurance/how-liability-arises/definition.js';
import { ProfessionalIndemnityPolicyCover } from '@/content/courses/nta-introduction-to-professional-indemnity-insurance/professional-Indemnity-policy-cover/definition.js';
import { ProfessionalIndemnityVsCyber } from '@/content/courses/nta-introduction-to-professional-indemnity-insurance/professional-indemnity-vs-cyber/definition.js';

// lmi-business-interruption
import { IntroductionToBusinessInterruption } from '@/content/courses/lmi-business-interruption/business-interruption/definition.js';

// dewer-pragmatic-leadership
import { WhatMakesAGreatLeader } from '@/content/courses/dewer-pragmatic-leadership/what-makes-a-great-leader/definition.js';
import { FourHabitsOfLeadershipPodcasts } from '@/content/courses/dewer-pragmatic-leadership/4-habits-of-leadership-podcasts/definition.js';
import { KeyIngredientsOfBuildingYourTeam } from '@/content/courses/dewer-pragmatic-leadership/key-ingredients-of-building-your-team/definition.js';
import { BringingYourLeadershipsSkillsTogether } from '@/content/courses/dewer-pragmatic-leadership/bringing-your-leaderships-skills-together/definition.js';

import { IntroductionToPresentingEmployersLiabilityRisks } from '@/content/courses/nta-presenting-employers-liability-risk/presenting-employers-liability-risk/definition.js';
import { ReviewingContractsForInsuranceImplications } from '@/content/courses/nta-reviewing-contracts-for-insurance-implications/reviewing-contracts-for-insurance-implications/definition.js';

import { CourtOperationsDuringCovid19 } from '@/content/courses/rradar-court-operations-during-covid-19/court-operations-during-covid-19/definition.js';

import { ConductRiskPartnerMeetingOurCustomersNeeds } from '@/content/courses/axa-conduct-risk-review/conduct-risk-partner-meeting-our-customers-needs/definition.js';

import { DemystifyingAlternativeRiskTransferArt } from '@/content/courses/nta-demystifying-alternative-risk-transfer/definition.js';

import { introductionToUnderinsuranceAndInflation } from '@/content/courses/nta-underinsurance-and-inflation/definition.js';

import { AdoptingASalesMindset } from '@/content/courses/nta-adopting-a-sales-mindset/definition.js';

import { MotorClaimsInflation } from '@/content/courses/axa-motor-claims-inflation/motor-claims-inflation/definition.js';

import { MergersAndAcquisitionsInsuranceIssues } from '@/content/courses/nta-mergers-and-acquisitions/mergers-and-acquisitions–insurance-issues/definition.js';
import { NetworkingForSuccess } from '@/content/courses/nta-networking-for-success/networking-for-success/definition.js';

import { PreActionProtocolsInNorthernIreland } from '@/content/courses/rradar-pre-action-protocols/pre-action-protocols-in-northern-ireland/definition.js';

import { AGuideToWorkplaceIntegrity } from '@/content/courses/zing365-soft-skills/a-guide-to-workplace-integrity/definition.js';
import { CommunicationEssentials } from '@/content/courses/zing365-soft-skills/communication-essentials/definition.js';
import { TelephoneEtiquette } from '@/content/courses/zing365-soft-skills/telephone-etiquette/definition.js';
import { CreatingAndDeliveringBusinessPresentations } from '@/content/courses/zing365-soft-skills/creating-and-delivering-business-presentations/definition.js';
import { BeTheBestVersionOfYourself } from '@/content/courses/zing365-soft-skills/be-the-best-version-of-yourself/definition.js';

import { MlpIntroductionToMlp } from '@/content/courses/axa-mlp-training/mlp-introduction-to-mlp/definition.js';
import { MlpDirectorsOfficersCover } from '@/content/courses/axa-mlp-training/mlp-directors-officers-cover/definition.js';
import { MlpCompanyLegalLiabilityCover } from '@/content/courses/axa-mlp-training/mlp-company-legal-liability-cover/definition.js';
import { MlpEmploymentPracticesLiabilityCover } from '@/content/courses/axa-mlp-training/mlp-employment-practices-liability-cover/definition.js';
import { MlpClaimsSmeRisks } from '@/content/courses/axa-mlp-training/mlp-claims-sme-risks/definition.js';

import { LithiumIonBatteries } from '@/content/courses/axa-lithium-ion-batteries/lithium-ion-batteries/definition.js';
import { ModernBuildingTechniques } from '@/content/courses/axa-modern-building-techniques/modern-building-techniques/definition.js';

import { EsgIntroAndRealEstateStrategy } from '@/content/courses/axa-esg-environmental-social-corporate-governance/esg-intro-and-real-estate-strategy/definition.js';
import { EsgUnderwritingUpdate } from '@/content/courses/axa-esg-environmental-social-corporate-governance/esg-underwriting-update/definition.js';
import { EsgEnvironmentalSocialAndCorporateGovernance } from '@/content/courses/axa-esg-environmental-social-corporate-governance/esg-environmental-social-and-corporate-governance/definition.js';

import { MindHealthAndWellbeingAtWork } from '@/content/courses/axa-mind-health-and-wellbeing-at-work/mind-health-and-wellbeing-at-work/definition.js';

import { AddressingTheNeedsOfExistingClientsForWinWinResults } from '@/content/courses/nta-addressing-the-needs-of-existing-clients/benefits-of-meeting-existing-clients-needs/definition.js';
import { KeySkillsAndApproachesForAddressingClientsNeeds } from '@/content/courses/nta-addressing-the-needs-of-existing-clients/key-skills-and-approaches-for-addressing-clients-needs/definition.js';
import { ChallengesToAchievingSuccessfulResultsAndStrategiesToOvercomeThem } from '@/content/courses/nta-addressing-the-needs-of-existing-clients/typical-challenges-you-need-to-overcome-to-help-meet-existing-clients-needs/definition.js';

import { FinancialWellbeingTheImpactOnMentalHealth } from '@/content/courses/axa-financial-wellbeing-the-impact-on-mental-health/financial-wellbeing-the-impact-on-mental-health/definition.js';

import { IntroductionToHospitality } from '@/content/courses/nta-sector-issues-and-insurance/introduction-to-hospitality/definition.js';
import { IntroductionToRetail } from '@/content/courses/nta-sector-issues-and-insurance/introduction-to-retail/definition.js';

//Zing365 Jan 2024
import { CultivatingDiversityInclusionAndBelongingAtWork } from '@/content/courses/zing-365-legislation/cultivating-diversity-inclusion-and-belonging-at-work/definition.js';

import { EnvironmentalRiskManagement } from '@/content/courses/zing-365-legislation/environmental-risk-management/definition.js';

import { FraudAndFraudulentClaims } from '@/content/courses/zing-365-legislation/fraud-and-fraudulent-claims/definition.js';

import { FcaIntroductionToRegulationOfGeneralInsuranceDistribution } from '@/content/courses/zing-365-legislation/fca-introduction-to-regulation-of-general-insurance-distribution/definition.js';

import { TheFutureOfRegulation } from '@/content/courses/zing-365-legislation/the-future-of-regulation/definition.js';

//NTA June 2024
import { IntroductionToTerrorismCoverSessionOne } from '@/content/courses/nta-introduction-to-terrorism-cover/introduction-to-terrorism-cover-session-1/definition.js';
import { IntroductionToTerrorismCoverSessionTwo } from '@/content/courses/nta-introduction-to-terrorism-cover/introduction-to-terrorism-cover-session-2/definition.js';

//Marketing Guide Feb 2025
import { HowToOrganiseOnlineEvents } from '@/content/courses/ign-marketing-guide/how-to-organise-online-events/definition.js';
import { HowToBestUnderstandYourCustomers } from '@/content/courses/ign-marketing-guide/how-to-best-understand-your-customers/definition.js';
import { HowToUseLinkedIn } from '@/content/courses/ign-marketing-guide/how-to-use-linked-in/definition.js';
import { HowToPerformAWebsiteHealthCheck } from '@/content/courses/ign-marketing-guide/how-to-perform-a-website-health-check/definition.js';
import { HowToVlog } from '@/content/courses/ign-marketing-guide/how-to-vlog/definition.js';

import { BurnoutToolkit } from '@/content/courses/ign-working-well-toolkit/burnout-toolkit/definition.js';
import { InductionPlanningTemplate } from '@/content/courses/ign-working-well-toolkit/induction-planning-template/definition.js';
import { NavigatingTheNewNormal } from '@/content/courses/ign-working-well-toolkit/navigating-the-new-normal/definition.js';
import { RemoteOnboardingChecklist } from '@/content/courses/ign-working-well-toolkit/remote-onboarding-checklist/definition.js';
import { ProtectingYourTopTalentFromBurnout } from '@/content/courses/ign-working-well-toolkit/protecting-your-top-talent-from-burnout/definition.js';
import { RemoteWorkingChecklist } from '@/content/courses/ign-working-well-toolkit/remote-working-checklist/definition.js';
import { SettingExpectations } from '@/content/courses/ign-working-well-toolkit/setting-expectations/definition.js';
import { TipsAndGuidanceForMonitoringProductivity } from '@/content/courses/ign-working-well-toolkit/tips-and-guidance-for-monitoring-productivity/definition.js';
import { TipsForSurveyingYourTeam } from '@/content/courses/ign-working-well-toolkit/tips-for-surveying-your-team/definition.js';
import { TenTipsForOnboardingRemotely } from '@/content/courses/ign-working-well-toolkit/10-tips-for-onboarding-remotely/definition.js';
import { TenTipsForVirtualBrainstorming } from '@/content/courses/ign-working-well-toolkit/10-tips-for-virtual-brainstorming/definition.js';
import { TopTipsForChallengingConversationsInAVirtualSetting } from '@/content/courses/ign-working-well-toolkit/top-tips-for-challenging-conversations-in-a-virtual-setting/definition.js';
import { WhatsAffectingProductivityAndMotivation } from '@/content/courses/ign-working-well-toolkit/whats-affecting-productivity-and-motivation/definition.js';
import { BestPracticeForOnboardingRemotelyAndManagingNewStarters } from '@/content/courses/ign-working-well-toolkit/best-practice-for-onboarding-remotely-and-managing-new-starters/definition.js';
import { ManagersGuideToSupportingMentalHealthAndWellbeingInTheWorkplace } from '@/content/courses/ign-working-well-toolkit/managers-guide-to-supporting-mental-health-and-wellbeing-in-the-workplace/definition.js';

let allModules = [];

allModules.push.apply(allModules, IntroductionToSocialMedia.module);
allModules.push.apply(allModules, IntroductionToLinkedin.module);
allModules.push.apply(allModules, HowToCreateALinkedInProfile.module);

allModules.push.apply(allModules, ManufacturingPropertyInsuranceIssuesAndImplications.module);
allModules.push.apply(
  allModules,
  ManufacturingBusinessInterruptionIssuesAndImplicationsPart_1.module
);
allModules.push.apply(
  allModules,
  ManufacturingBusinessInterruptionIssuesAndImplicationsPart_2.module
);
allModules.push.apply(
  allModules,
  ManufacturingLiabilityAndMotorInsuranceIssuesAndImplications.module
);

allModules.push.apply(allModules, ConstructionMarketSegmentationAndKeyIssues.module);
allModules.push.apply(allModules, ConstructionLiabilityIssuesInTheConstructionSectorPart_1.module);
allModules.push.apply(allModules, ConstructionLiabilityIssuesInTheConstructionSectorPart_2.module);
allModules.push.apply(
  allModules,
  ConstructionContractorsAllRisksErectionAllRisksAndBiIssues.module
);
allModules.push.apply(allModules, ConstructionOtherCoverOptionsForTheConstructionIndustry.module);

allModules.push.apply(allModules, SalesProcessOverview.module);
allModules.push.apply(allModules, AdaptingToAHardeningMarketAndStrategicSelling.module);
allModules.push.apply(allModules, BecomingASolutionsProvider.module);
allModules.push.apply(allModules, SocialSellingAndVirtualNetworking.module);
allModules.push.apply(allModules, EffectiveCommunicationInAVirtualWorld.module);
allModules.push.apply(allModules, VirtualMeetings.module);

allModules.push.apply(allModules, TheDynamicsOfTheInsuranceMarketCycle.module);
allModules.push.apply(allModules, TradingInAHardMarket.module);
allModules.push.apply(allModules, YourPersonalEffectivenessInAHardMarket.module);

allModules.push.apply(allModules, VirtualMeetingsSettingYourselfUpForSuccess.module);
allModules.push.apply(allModules, ImpactfulCommunicationsAndRapport.module);

allModules.push.apply(allModules, WhatIsWorkBurnout.module);
allModules.push.apply(allModules, StagesOfBurnout.module);
allModules.push.apply(allModules, HowToTalkToYourBossAboutBurnout.module);
allModules.push.apply(allModules, MythsAboutBurnout.module);

allModules.push.apply(allModules, RealEstateMarketSegmentationAndKeyIssues.module);
allModules.push.apply(
  allModules,
  RealEstateConstructionLeasesAndLendersInTheRealEstateSector.module
);
allModules.push.apply(
  allModules,
  RealEstatePropertyInsuranceKeyCoverIssuesInPropertyOwnersPolicies.module
);
allModules.push.apply(
  allModules,
  RealEstateIncomeProtectionLossOfRentAndAlternativeAccommodation.module
);
allModules.push.apply(
  allModules,
  RealEstateAdditionalPoliciesToConsiderForTheRealEstateSector.module
);

allModules.push.apply(allModules, ActusEmployeeeWellbeingAndResilience.module);

allModules.push.apply(allModules, ManagingSicknessAbsence.module);
allModules.push.apply(allModules, PerformanceManagement.module);
allModules.push.apply(allModules, Discrimination.module);

allModules.push.apply(allModules, YourBehavioursMatter.module);
allModules.push.apply(allModules, FlexingYourApproachToDriveEngagement.module);
allModules.push.apply(allModules, MotivatingYourTeamForImprovedPerformance.module);

allModules.push.apply(allModules, ManagingChange.module);
allModules.push.apply(allModules, DealingWithChange.module);
allModules.push.apply(allModules, LeadingChange.module);

allModules.push.apply(allModules, AgreeingSmarterObjectives.module);
allModules.push.apply(allModules, Effective121sAndAppraisals.module);
allModules.push.apply(allModules, KeyCommunicationSkillsAndSelfAwareness.module);

allModules.push.apply(allModules, KeyCommunicationSkillsSelfAwareness.module);
allModules.push.apply(allModules, SettingSmarterObjectivesRemotely.module);
allModules.push.apply(allModules, Effective121sAndAppraisalsInAHybridWorld.module);
allModules.push.apply(allModules, DeliverFeedbackForPositiveOutcomes.module);
allModules.push.apply(allModules, ManageUnderperformanceInAHybridSetting.module);

allModules.push.apply(allModules, NurturingYourMentalHealth.module);
allModules.push.apply(allModules, KeepingYourHighPerformersPerforming.module);
allModules.push.apply(allModules, InspiringHighPerformanceFromYourTeamWhenThePressureIsOn.module);
allModules.push.apply(allModules, TheExtraOnePerformanceEdge.module);

allModules.push.apply(allModules, ElectricVehiclesTechnicalTraining.module);

allModules.push.apply(allModules, IntroductionToTheSalesProcess.module);
allModules.push.apply(allModules, IntroductionToSalesProspectingOne.module);
allModules.push.apply(allModules, IntroductionToSalesProspectingTwo.module);
allModules.push.apply(allModules, IntroductionToSalesConnectingOne.module);
allModules.push.apply(allModules, IntroductionToSalesConnectingTwo.module);
allModules.push.apply(allModules, IntroductionToSalesNeedsDiscoveryOne.module);
allModules.push.apply(allModules, IntroductionToSalesNeedsDiscoveryTwo.module);
allModules.push.apply(allModules, IntroductionToSalesSolutionProposal.module);
allModules.push.apply(allModules, IntroductionToSalesObjectionHandling.module);
allModules.push.apply(allModules, IntroductionToSalesClosing.module);

allModules.push.apply(allModules, HowLiabilityArises.module);
allModules.push.apply(allModules, ProfessionalIndemnityPolicyCover.module);
allModules.push.apply(allModules, ProfessionalIndemnityVsCyber.module);

allModules.push.apply(allModules, IntroductionToBusinessInterruption.module);

allModules.push.apply(allModules, WhatMakesAGreatLeader.module);
allModules.push.apply(allModules, FourHabitsOfLeadershipPodcasts.module);
allModules.push.apply(allModules, KeyIngredientsOfBuildingYourTeam.module);
allModules.push.apply(allModules, BringingYourLeadershipsSkillsTogether.module);

allModules.push.apply(allModules, IntroductionToPresentingEmployersLiabilityRisks.module);
allModules.push.apply(allModules, ReviewingContractsForInsuranceImplications.module);

allModules.push.apply(allModules, CourtOperationsDuringCovid19.module);

allModules.push.apply(allModules, ConductRiskPartnerMeetingOurCustomersNeeds.module);

allModules.push.apply(allModules, DemystifyingAlternativeRiskTransferArt.module);
allModules.push.apply(allModules, introductionToUnderinsuranceAndInflation.module);

allModules.push.apply(allModules, AdoptingASalesMindset.module);
allModules.push.apply(allModules, MotorClaimsInflation.module);
allModules.push.apply(allModules, MergersAndAcquisitionsInsuranceIssues.module);
allModules.push.apply(allModules, NetworkingForSuccess.module);
allModules.push.apply(allModules, PreActionProtocolsInNorthernIreland.module);

allModules.push.apply(allModules, AGuideToWorkplaceIntegrity.module);
allModules.push.apply(allModules, CommunicationEssentials.module);
allModules.push.apply(allModules, TelephoneEtiquette.module);
allModules.push.apply(allModules, CreatingAndDeliveringBusinessPresentations.module);
allModules.push.apply(allModules, BeTheBestVersionOfYourself.module);

allModules.push.apply(allModules, MlpIntroductionToMlp.module);
allModules.push.apply(allModules, MlpDirectorsOfficersCover.module);
allModules.push.apply(allModules, MlpCompanyLegalLiabilityCover.module);
allModules.push.apply(allModules, MlpEmploymentPracticesLiabilityCover.module);
allModules.push.apply(allModules, MlpClaimsSmeRisks.module);

allModules.push.apply(allModules, LithiumIonBatteries.module);
allModules.push.apply(allModules, ModernBuildingTechniques.module);

allModules.push.apply(allModules, EsgIntroAndRealEstateStrategy.module);
allModules.push.apply(allModules, EsgUnderwritingUpdate.module);
allModules.push.apply(allModules, EsgEnvironmentalSocialAndCorporateGovernance.module);

allModules.push.apply(allModules, MindHealthAndWellbeingAtWork.module);

allModules.push.apply(allModules, AddressingTheNeedsOfExistingClientsForWinWinResults.module);
allModules.push.apply(allModules, KeySkillsAndApproachesForAddressingClientsNeeds.module);
allModules.push.apply(
  allModules,
  ChallengesToAchievingSuccessfulResultsAndStrategiesToOvercomeThem.module
);

allModules.push.apply(allModules, FinancialWellbeingTheImpactOnMentalHealth.module);

allModules.push.apply(allModules, IntroductionToHospitality.module);
allModules.push.apply(allModules, IntroductionToRetail.module);

allModules.push.apply(allModules, CultivatingDiversityInclusionAndBelongingAtWork.module);
allModules.push.apply(allModules, EnvironmentalRiskManagement.module);
allModules.push.apply(allModules, FraudAndFraudulentClaims.module);
allModules.push.apply(allModules, FcaIntroductionToRegulationOfGeneralInsuranceDistribution.module);
allModules.push.apply(allModules, TheFutureOfRegulation.module);


allModules.push.apply(allModules, IntroductionToTerrorismCoverSessionOne.module);
allModules.push.apply(allModules, IntroductionToTerrorismCoverSessionTwo.module);

allModules.push.apply(allModules, HowToOrganiseOnlineEvents.module);
allModules.push.apply(allModules, HowToBestUnderstandYourCustomers.module);
allModules.push.apply(allModules, HowToUseLinkedIn.module);
allModules.push.apply(allModules, HowToPerformAWebsiteHealthCheck.module);
allModules.push.apply(allModules, HowToVlog.module);

allModules.push.apply(allModules, BurnoutToolkit.module);
allModules.push.apply(allModules, InductionPlanningTemplate.module);
allModules.push.apply(allModules, NavigatingTheNewNormal.module);
allModules.push.apply(allModules, RemoteOnboardingChecklist.module);
allModules.push.apply(allModules, ProtectingYourTopTalentFromBurnout.module);
allModules.push.apply(allModules, RemoteWorkingChecklist.module);
allModules.push.apply(allModules, SettingExpectations.module);
allModules.push.apply(allModules, TipsAndGuidanceForMonitoringProductivity.module);
allModules.push.apply(allModules, TipsForSurveyingYourTeam.module);
allModules.push.apply(allModules, TenTipsForOnboardingRemotely.module);
allModules.push.apply(allModules, TenTipsForVirtualBrainstorming.module);
allModules.push.apply(allModules, TopTipsForChallengingConversationsInAVirtualSetting.module);
allModules.push.apply(allModules, WhatsAffectingProductivityAndMotivation.module);
allModules.push.apply(allModules, BestPracticeForOnboardingRemotelyAndManagingNewStarters.module);
allModules.push.apply(allModules, ManagersGuideToSupportingMentalHealthAndWellbeingInTheWorkplace.module);

const modules = allModules;

export const ModuleContent = {
  modules,
};
