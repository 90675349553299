const module = [
  {
    id: '3a2859b6-6b31-4482-8b1e-32c362f48bb8',
    slug: '10-tips-for-virtual-brainstorming',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: '3a2859b6-6b31-4482-8b1e-32c362f48bb8|d1c90dbc-6e2e-4e77-90c3-319a5bc220a6',
    template: 'pdf-template',
    title: '10 Tips For Virtual Brainstorming',
    description:
      'Read our top ten tips for running a virtual brainstorming session with your team.',
    completedDescription: 'TODO',
    supplier: 'Ignition NBS',
    categoryId: 'd1c90dbc-6e2e-4e77-90c3-319a5bc220a6',
    questionnaireId: '',
    contents: {
      id: '9d0db268-1f3e-4e06-a803-1c9b8457108f',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'Read our top ten tips for running a virtual brainstorming session with your team.' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>Sharing information in advance</li>' +
        '<li>Use digital tools to aid brainstorming</li>' +
        '<li>Allow time for reflection</li>' +
        '<li>Explain next steps and follow up on actions</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'document',
          subType: 'pdf',
          title: '10 tips for virtual brainstorming',
          button: '',
          fileSize: 0,
          url: '10-tips-for-virtual-brainstorming.pdf',
          caption: {
            title: '',
            description:
              ''
          }
        },
        {
          type: 'file',
          subType: 'pdf',
          title: '10 tips for virtual brainstorming',
          button: 'Download File',
          fileSize: '362244',
          url: '10-tips-for-virtual-brainstorming.pdf',
          caption: {
            title: '',
            description: ''
          },
          axaUrl: ''
        }
      ]
    },
    progress: {
      value: 1,
      maximum: 1
    },
    statistics: {
      level: 0,
      timeEstimate: '30'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['management','leadership','skills']
  },
];

export const TenTipsForVirtualBrainstorming = {
  module
};