const module = [
  {
    id: '1d83e252-4308-4cc1-845d-cb2641a7205b',
    slug: 'how-to-best-understand-your-customers',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: '1d83e252-4308-4cc1-845d-cb2641a7205b|10c10b32-fca4-497c-8694-e9f3e81fab20',
    template: 'pdf-template',
    title: 'How to best understand your customers',
    description:
      "This ‘How to’ guide takes a look at how you can understand what your customers truly value.",
    completedDescription: 'TODO',
    supplier: 'Ignition NBS',
    categoryId: '10c10b32-fca4-497c-8694-e9f3e81fab20',
    questionnaireId: '',
    contents: {
      id: 'd1a6d14b-4e95-4dfb-98ff-84e91ba40cf1',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'In this guide you’ll understand all the benefits and steps you need to consider making your online events a success.' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>Benefits of understanding your customers</li>' +
        '<li>6 Things you need to know about your customers.</li>' +
        '<li>Putting yourself in your customers shoes.</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'document',
          subType: 'pdf',
          title: 'How to best understand your customers',
          button: '',
          fileSize: 0,
          url: 'how-to-best-understand-your-customers.pdf',
          caption: {
            title: '',
            description:
              ''
          }
        },
        {
          type: 'file',
          subType: 'pdf',
          title: 'How to best understand your customers',
          button: 'Download File',
          fileSize: '256990',
          url: 'how-to-best-understand-your-customers.pdf',
          caption: {
            title: '',
            description: ''
          },
          axaUrl: ''
        }
      ]
    },
    progress: {
      value: 1,
      maximum: 1
    },
    statistics: {
      level: 0,
      timeEstimate: '30'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['marketing', 'skills']
  },
];

export const HowToBestUnderstandYourCustomers = {
  module
};