const module = [
  {
    id: '536e1af3-eba4-42ae-b458-9985098c7ed9',
    slug: '10-tips-for-onboarding-remotely',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: '536e1af3-eba4-42ae-b458-9985098c7ed9|d1c90dbc-6e2e-4e77-90c3-319a5bc220a6',
    template: 'pdf-template',
    title: '10 tips for onboarding remotely',
    description:
      'Read our top 10 tips for a successful remote onboarding process.',
    completedDescription: 'TODO',
    supplier: 'Ignition NBS',
    categoryId: 'd1c90dbc-6e2e-4e77-90c3-319a5bc220a6',
    questionnaireId: '',
    contents: {
      id: '9e9262bf-5770-4587-860f-3e485ff2a919',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'From delivery of IT equipment and booking induction meetings through to assigning a buddy and encouraging social interactions we’ve included these tips in one handy guide.',
      heroImage: false,
      media: [
        {
          type: 'document',
          subType: 'pdf',
          title: '10 tips for onboarding remotely',
          button: '',
          fileSize: 0,
          url: '10-tips-for-onboarding-remotely.pdf',
          caption: {
            title: '',
            description:
              ''
          }
        },
        {
          type: 'file',
          subType: 'pdf',
          title: '10 tips for onboarding remotely',
          button: 'Download File',
          fileSize: '118292',
          url: '10-tips-for-onboarding-remotely.pdf',
          caption: {
            title: '',
            description: ''
          },
          axaUrl: ''
        }
      ]
    },
    progress: {
      value: 1,
      maximum: 1
    },
    statistics: {
      level: 0,
      timeEstimate: '30'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['management']
  },
];

export const TenTipsForOnboardingRemotely = {
  module
};