const courses = [
  {
    type: 'course',
    id: '06d81203-f996-435c-b246-6b25422c35d6',
    active: true,
    title: 'Sector issues and insurance',
    description:
      'Prepared and delivered Nick Thomas and Diane Jenkins.  This course covers the key risks and cover issues affecting specific sectors.',
    backgroundColour: '#beb6d0',
    backTextColour: '#000',
    emptyCardBackground: '#efefef',
    defaults: {
      open: true,
    },
  },
  {
    type: 'course',
    id: '7e9e8508-7258-4b00-a543-074414cb5f36',
    active: true,
    title: 'Financial wellbeing: the impact on mental health',
    description:
      'The rising cost of living is having a significant impact on financial wellbeing.  We look at the link between financial health and mental health.',
    backgroundColour: 'rgb(205, 232, 135)',
    backTextColour: '#000',
    emptyCardBackground: '#9dbfc4',
    defaults: {
      open: true,
    },
  },
  {
    type: 'course',
    id: '3038339a-ac33-42da-bf74-7dd66270aed5',
    active: true,
    title: 'Mind Health and Wellbeing at work',
    description:
      'Join AXA Health’s Jase Edgar and mental health consultancy lead, Eugene Farrell as they discuss the ever-changing nature of mental wellbeing in the workplace.',
    backgroundColour: '#5295d6',
    backTextColour: '#fff',
    emptyCardBackground: '#b3d4f3',
    defaults: {
      open: true,
    },
  },
  {
    type: 'course',
    id: '614ab504-1537-40f3-a114-72250a217d3f',
    active: true,
    title: 'Environmental Social and Corporate Governance (ESG)',
    description: '???',
    backgroundColour: '#93c5cb',
    backTextColour: '#333',
    emptyCardBackground: '#e1e8e8',
    defaults: {
      open: true,
    },
  },
  {
    type: 'course',
    id: 'd139596c-fd9b-4651-815a-6de33d2601ab',
    active: true,
    title: 'Technical Training - Modern Building Techniques',
    description:
      'Prepared and delivered by Rob Dakin, Head of Business Resilience Management Delivery AXA Insurance. This learning module explores some contemporary methods of building and the challenges we face with modern structures when affected by perils such as fire, escape of water and flood.',
    backgroundColour: '#93c5cb',
    backTextColour: '#333',
    emptyCardBackground: '#e1e8e8',
    defaults: {
      open: true,
    },
  },
  {
    type: 'course',
    id: 'efba1b84-412d-46e7-bea0-05adf97e06af',
    active: true,
    title: 'Technical Training - Lithium-ion Batteries',
    description:
      'Prepared and delivered by Rob Dakin, Head of Business Resilience Management Delivery AXA Insurance. This learning module is designed to give you a wider understanding of the use of lithium batteries and associated risks.',
    backgroundColour: '#93c5cb',
    backTextColour: '#333',
    emptyCardBackground: '#e1e8e8',
    defaults: {
      open: true,
    },
  },
  {
    type: 'course',
    id: '349708b6-4bdc-49a5-a851-fbd4d574b56d',
    active: true,
    title: 'Soft Skills',
    description:
      '<p>In this course we will look at some of the soft but important skills and what impact they will have on your working and personal life.</p>' +
      '<p>Prepared and delivered by Zing365</p>',
    backgroundColour: '#cde887',
    backTextColour: '#000',
    emptyCardBackground: '#ebf4d1',
    defaults: {
      open: true,
    },
  },
  {
    type: 'course',
    id: 'f698ff3b-eb6f-4635-8f7e-a81c6d3f69ae',
    active: true,
    title: 'Management Liability Insurance (MLP) Training',
    description:
      'An Introduction to MLP for all brokers, client facing, new business or retention roles. Aimed at entry level to give a basic understanding of Management Liability Insurance. ',
    backgroundColour: '#25bbd1',
    backTextColour: '#000',
    emptyCardBackground: '#b2e0e7',
    defaults: {
      open: true,
    },
  },
  {
    type: 'course',
    id: 'b7ff23a6-9aaf-4b4a-9f8d-e7b6e73c3576',
    active: true,
    title: 'Pre-action Protocols in Northern Ireland',
    description:
      'Prepared and delivered by rradar and Carson McDowell this learning module is designed to give you an overview of Pre-action Protocols claims in NI',
    backgroundColour: '#f25a5a',
    backTextColour: '#fff',
    emptyCardBackground: '#e2ac9a',
    defaults: {
      open: true,
    },
  },
  {
    type: 'course',
    id: '3187c558-5836-4721-a158-776c11b266ee',
    active: true,
    title: ' Addressing the needs of existing clients – for win-win results',
    description:
      'In another one of his sales masterclasses Nick Thomas looks at how cross selling can help address the needs of existing clients to build trust and maximise the relationship.  ',
    backgroundColour: '#87a2ef',
    backTextColour: '#000',
    emptyCardBackground: '#ebf0ff',
    defaults: {
      open: true,
    },
  },
  {
    type: 'course',
    id: 'b2600438-db35-4571-8030-722ae6a5e470',
    active: true,
    title: 'Networking For Success',
    description: '???',
    backgroundColour: '#cccb59',
    backTextColour: '#000',
    emptyCardBackground: '#d0cea5',
    defaults: {
      open: true,
    },
  },
  {
    type: 'course',
    id: 'cb299f5f-2a2e-48a7-a555-df263578556c',
    active: true,
    title: 'Mergers And Acquisitions',
    description:
      'Prepared and delivered by Nick Thomas and Associates, covering the key insurance issues with Mergers & Acquisitions and describing the types of mergers/acquisitions, the key insurance considerations and additional insurance products',
    backgroundColour: '#b390dd',
    backTextColour: '#000',
    emptyCardBackground: '#dacee8',
    defaults: {
      open: true,
    },
  },

  {
    type: 'course',
    id: '56835389-6712-4c05-b212-f19d6095067b',
    active: true,
    title: 'Motor Claims Inflation ',
    description: '???',
    backgroundColour: '#9ae8e8',
    backTextColour: '#000',
    emptyCardBackground: '#eff5f5',
    defaults: {
      open: true,
    },
  },
  {
    type: 'course',
    id: '78626287-b0ac-4384-a96c-77d172323d18',
    active: true,
    title: 'Demystifying Alternative Risk Transfer (ART)',
    description:
      'Prepared and delivered by Nick Thomas and Diane Jenkins this learning module is designed to give you an overview of the main types of alternative risk transfer and identify the key issues when considering alternative risk transfer with a client.',
    backgroundColour: '#9ae8e8',
    backTextColour: '#000',
    emptyCardBackground: '#eff5f5',
    defaults: {
      open: true,
    },
  },
  {
    type: 'course',
    id: 'f509e87d-97bb-411d-a145-a0dda3f0a5d4',
    active: true,
    title: 'Environmental, Social and Governance (ESG)',
    description:
      'We have a series of modules focussing on legislation and regulation in the insurance industry.  From a general introduction to regulation through fraudulent claims and what the future of regulation has in store for the insurance industry.',
    backgroundColour: '#12aec5',
    backTextColour: '#fff',
    emptyCardBackground: '#d0f9ff',
    defaults: {
      open: true,
    },
  },
  {
    type: 'course',
    id: 'e016af10-18a0-4fbd-b48d-0df0e59ede7d',
    active: true,
    title: 'Introduction to Terrorism cover',
    description:
      'Prepared and delivered Nick Thomas and Diane Jenkins.  This course provides you with advice on Terrorism cover and risk management',
    backgroundColour: '#FFA500',
    backTextColour: '#000',
    emptyCardBackground: '#FFE5B4',
    defaults: {
      open: true,
    },
  },
  {
    type: 'course',
    id: '586e1d86-9cd3-4040-8fa1-c7ed3e93d708',
    active: true,
    title: 'Court Operations During Covid-19',
    description:
      'Prepared and delivered by rradar and Carson McDowell this learning module is designed to give you an overview of the changes in Court Operations During Covid-19.',
    backgroundColour: '#f25a5a',
    backTextColour: '#fff',
    emptyCardBackground: '#e2ac9a',
    defaults: {
      open: true,
    },
  },
  {
    type: 'course',
    id: '96217a28-8620-4869-9b36-ab53bc19cc14',
    active: true,
    title: 'Presenting Employers Liability Risks',
    description:
      'Prepared and delivered by Nick Thomas and Diane Jenkins this learning module is designed to give you an overview of the key information you need to include in an Employer Liability (EL) presentation to obtain the best terms and understand why claims triangulations are so important. ',
    backgroundColour: '#97b5a9',
    backTextColour: '#222',
    emptyCardBackground: '#e3e9e7',
    defaults: {
      open: true,
    },
  },
  {
    type: 'course',
    id: '6bf4d444-daf6-4a42-b9bc-3e6e7a8d3d44',
    active: true,
    title: 'Reviewing contracts for insurance implications',
    description:
      'Prepared and delivered by Nick Thomas and Diane Jenkins this learning module ‘Reviewing contracts for insurance implications’ is one of our advanced training modules designed for more experienced and technically advanced delegates ',
    backgroundColour: '#b4ceeb',
    backTextColour: '#222',
    emptyCardBackground: '#f0f5fa',
    defaults: {
      open: true,
    },
  },
  {
    type: 'course',
    id: 'f2aabd5b-8118-4cbb-aa4a-c0e719f8d459',
    active: true,
    title: 'Pragmatic Leadership',
    description:
      'In this series of toolbox talks podcasts we’re joined by Fiona Dewar, founder of Pragmatic Leadership a training business that helps develop great managers and leaders. ',
    backgroundColour: '#d6887b',
    backTextColour: '#222',
    emptyCardBackground: '#ebbeb7',
    defaults: {
      open: true,
    },
  },
  {
    type: 'course',
    id: 'c306cd2c-4df9-4206-85cc-a33d0bcf6762',
    active: true,
    title: 'Introduction To Sales',
    description:
      'Prepared and delivered by Nick Thomas and Associates this series of modules are designed to support anyone new to sales or looking to refresh their sales skills.  It explores each step of the sales process from prospecting through to closing the sale and everything in between.',
    backgroundColour: '#b4b0d7',
    backTextColour: '#222',
    emptyCardBackground: '#EEEEFD',
    defaults: {
      open: true,
    },
  },
  {
    type: 'course',
    id: '33c63463-c631-4478-9115-ae51dda1931d',
    active: true,
    title: 'Introduction to Professional Indemnity Insurance',
    description:
      'Prepared and delivered by Nick Thomas and Associates this series of modules are designed to give an overview of Professional Indemnity Insurance, covering off – How liability arises, what’s usually included in cover and the differences between Professional Indemnity and Cyber insurance.',
    backgroundColour: '#a8a6bb',
    backTextColour: '#222',
    emptyCardBackground: '#e8e7ef',
    defaults: {
      open: true,
    },
  },
  {
    type: 'course',
    id: '5d12de43-63e2-4939-b7ab-2f5677f9cc1c',
    active: true,
    title: 'Insurance Bites',
    description:
      'In these modules we’re joined by Steve Manning, CEO of LMI group, through real life, practical examples you’ll learn why Business Interruption insurance is a policy of indemnity, what it means and how it works. ',
    backgroundColour: '#edc620',
    backTextColour: '#222',
    emptyCardBackground: '#fbeba8',
    defaults: {
      open: true,
    },
  },
  {
    type: 'course',
    id: 'd68963e3-4b1b-4af9-9d70-c9399c58d809',
    active: true,
    title: 'Toolbox Talks',
    description:
      "Our Toolbox Talks provide convenient, quick and effective podcast style modules to share knowledge. We hope you'll enjoy and share these with your colleagues.",
    backgroundColour: '#f99690',
    backTextColour: '#222',
    emptyCardBackground: '#f6d8d6',
    defaults: {
      open: true,
    },
  },
  {
    type: 'course',
    id: '067d1298-cbed-4fa4-b3da-de09893da510',
    active: true,
    title: 'Nurturing your mental health',
    description:
      'In this webinar we’re joined by the physiologists from AXA Health as they discuss how the importance of nurturing your mental health.',
    backgroundColour: '#99ad25',
    backTextColour: '#222',
    emptyCardBackground: '#cad48f',
    defaults: {
      open: true,
    },
  },
  {
    type: 'course',
    id: '8221d7be-45de-47ee-aba9-33124c54d4a8',
    active: true,
    title: 'Electric Vehicles – charging ahead',
    description:
      'Prepared and delivered by Doug Jenkins, this module is aimed at anyone who is advising their clients who are considering Electric Vehicles for the motor fleet.',
    backgroundColour: '#aed8e4',
    backTextColour: '#222',
    emptyCardBackground: '#c6e6ec',
    defaults: {
      open: true,
    },
  },
  {
    type: 'course',
    id: '9ecd64a0-66a5-4ef7-8ba6-f0a39841849f',
    active: true,
    title: 'People Management Series',
    description:
      'These modules from employment law specialists rradar will equip people managers with the knowledge and skills required to handle challenging employment situations.',
    backgroundColour: '#f25a5a',
    backTextColour: '#fff',
    emptyCardBackground: '#e2ac9a',
    defaults: {
      open: true,
    },
  },
  {
    type: 'course',
    id: '550971f5-d422-4a1a-9b61-6b167e40b959',
    active: true,
    title: 'Leadership Behaviours and Tools For Success',
    description:
      'In this programme created by Pragmatic Leadership, learn how to adopt the habits and behaviours that differentiate good managers from great leaders in order to get the best out of your team.',
    backgroundColour: '#60b9a0',
    backTextColour: '#000',
    emptyCardBackground: '#a3d3c5',
    defaults: {
      open: true,
    },
  },
  {
    type: 'course',
    id: '80ea6f13-2d75-4b83-9c42-02ef20d57166',
    active: true,
    title: 'Resilience and Wellbeing',
    description:
      'Prepared by Actus Consulting, this course is designed for anyone who wants to find ways to improve their physical and mental health and wellbeing.',
    backgroundColour: '#4ccedd',
    backTextColour: '#222',
    emptyCardBackground: '#ace8ef',
    defaults: {
      open: true,
    },
  },
  {
    type: 'course',
    id: '3f0b220a-d4d9-49c0-a49c-fc90c26de19b',
    slug: 'thriving-in-the-hard-market',
    active: true,
    title: 'Thriving In The Hard Market',
    description:
      'In collaboration with Nick Thomas and Diane Jenkins, this course is principally targeted at Account Executives and Account Handlers and will provide you with the skills needed to understand the dynamics of the insurance cycle and thrive in a hard market.',
    backgroundColour: '#c2efb9',
    backTextColour: '#222',
    emptyCardBackground: '#e3efe1',
    defaults: {
      open: false,
    },
  },
  {
    type: 'course',
    id: '8564b263-b8f3-47d5-b2d8-680bb2f38ea6',
    slug: 'marketing',
    active: true,
    title: 'Marketing',
    description:
      'This course is designed for Brokers who wish to improve their marketing skills, recognise good and bad practice and enhance their customer engagement',
    backgroundColour: '#e47f81',
    backTextColour: '#000',
    emptyCardBackground: '#fff5f5',
    defaults: {
      open: false,
    },
  },
  {
    type: 'course',
    id: '07ceadc8-32da-4dab-9bef-da023c3388eb',
    slug: 'technical-construction',
    active: true,
    title: 'Technical - Construction',
    description:
      'Delivered by industry specialist, Diane Jenkins, this programme is designed for Brokers who wish to enhance their practical knowledge of the Construction sector.',
    backgroundColour: 'rgb(241 150 92)',
    backTextColour: '#222',
    emptyCardBackground: '#fff1e2',
    defaults: {
      open: true,
    },
  },
  {
    type: 'course',
    id: '32f67180-3d5b-4522-82d8-aec3c6be0971',
    slug: 'defeating-work-burnout',
    active: true,
    title: 'Defeating Work Burnout',
    description:
      "Burnout is a serious health condition that impacts around 23% of workers. In this course, we'll discuss what is work burnout, including the symptoms, trends and solutions.",
    backgroundColour: '#f6edea',
    backTextColour: '#222',
    emptyCardBackground: '#fffafa',
    defaults: {
      open: false,
    },
  },
  {
    type: 'course',
    id: '1d903ecd-b454-426a-8d43-a44307fd4ab7',
    slug: 'sales-in-a-changing-environment',
    active: true,
    title: 'Sales In A Changing Environment',
    description:
      'In collaboration with Nick Thomas, this course is designed to provide employees in front-line sales or client-facing roles with the core sales skills, methodologies and training to adapt to a rapidly changing, remote trading environment.',
    backgroundColour: '#ebe2c7',
    backTextColour: '#222',
    emptyCardBackground: '#faf6ed',
    defaults: {
      open: true,
    },
  },
  {
    type: 'course',
    id: '31e36ecf-9b1e-4831-9193-665e8534fe0c',
    slug: 'technical-manufacturing',
    active: true,
    title: 'Technical - Manufacturing',
    description:
      'Delivered by industry specialist, Diane Jenkins, this programme is designed for Brokers who wish to enhance their practical knowledge of the Manufacturing sector, covering topics such as property, business interruption, liability and motor insurance issues for manufacturers.',
    backgroundColour: '#cbdae2',
    backTextColour: '#222',
    emptyCardBackground: '#f5f6f9',
    defaults: {
      open: true,
    },
  },
  {
    type: 'course',
    id: 'f0a2b81c-6127-4e34-a22d-e24cd5f58c5f',
    slug: 'technical-real-estate',
    active: true,
    title: 'Technical - Real Estate',
    description:
      'Prepared and delivered by Nick Thomas & Associates, this programme is designed for Brokers who wish to deepen their understanding of the key insurance issues facing the real estate sector.',
    backgroundColour: '#a1b3bd',
    backTextColour: '#222',
    emptyCardBackground: '#dee9ef',
    defaults: {
      open: true,
    },
  },
  {
    type: 'course',
    id: '0634f0ad-b1be-4a8d-8975-dd46dee3742d',
    slug: 'impactful-communication-in-a-virtual-trading-environment',
    active: true,
    title: 'Impactful Communication In A Virtual Trading Environment',
    description:
      'In collaboration with Nick Thomas and Lorna Reeves, this course is designed for employees at all levels and will develop your ability to communicate impactfully on virtual platforms, build rapport and conduct effective virtual meetings.',
    backgroundColour: '#ffd6a5',
    backTextColour: '#222',
    emptyCardBackground: '#f1e5d7',
    defaults: {
      open: false,
    },
  },
  {
    type: 'course',
    id: '76c64d1c-38a4-4ac5-824c-31ef12b91ac7',
    slug: 'change-management-series',
    active: true,
    title: 'Change Management Series',
    description:
      'Prepared and delivered by Actus Consulting, these modules are designed to support anyone who is experiencing change within their organisation. It explores how to effectively manage change and become a change superhero.',
    backgroundColour: '#80add3',
    backTextColour: '#222',
    emptyCardBackground: '#d1dde7',
    defaults: {
      open: false,
    },
  },
  {
    type: 'course',
    id: '9ddda366-d724-4ef0-8a75-248eda696817',
    slug: 'hybrid-management',
    active: true,
    title: 'Hybrid Management',
    description:
      'Prepared by Actus Consulting, these modules are designed to support line managers manage their team whilst working in a hybrid environment between home and the office.',
    backgroundColour: '#c5ae93',
    backTextColour: '#222',
    emptyCardBackground: '#bfb5aa',
    defaults: {
      open: false,
    },
  },
  {
    type: 'course',
    id: 'f7673de3-978f-4260-9f29-46f2c6700c0b',
    slug: 'employee-series',
    active: true,
    title: 'Employee Series',
    description:
      'Developed by Actus Consulting, these modules are designed to support anyone who is now having to adjust to working remotely.  It builds on existing topics such as having effective 121’s, agreeing SMARTER objectives and building on key communication skills and tailors them for the remote way of working.',
    backgroundColour: '#85b778',
    backTextColour: '#222',
    emptyCardBackground: '#cdd9ca',
    defaults: {
      open: false,
    },
  },
  {
    type: 'course',
    id: 'b6e92936-69ae-4393-b518-3d5b8d4f3dfd',
    slug: 'underinsurance-and-inflation',
    active: true,
    title: 'Underinsurance and Inflation',
    description:
      "Prepared and delivered by Nick Thomas and Diane Jenkins this learning module introduces you to ‘Underinsurance and Inflation and it's General effect of inflation on insurance, cover considerations and claims issues",
    backgroundColour: '#fcd385',
    backTextColour: '#222',
    emptyCardBackground: '#ffeecd',
    defaults: {
      open: false,
    },
  },
  {
    type: 'course',
    id: '4c2ac334-4d5d-4213-b2ad-6189b7c63761',
    slug: 'adopting-a-sales-mindset',
    active: true,
    title: 'Adopting a Sales Mindset',
    description:
      'In the latest in our series of Toolbox Talks we’re speaking to Nick Thomas, sales trainer, coach and consultant as we’ll be discussing how adopting a sales mindset can reap rewards in business even if you think sales isn’t your role',
    backgroundColour: '#c5b6c9',
    backTextColour: '#222',
    emptyCardBackground: '#e6d8ea',
    defaults: {
      open: false,
    },
  },
  {
    type: 'course',
    id: '10c10b32-fca4-497c-8694-e9f3e81fab20',
    slug: 'marketing-guide',
    active: true,
    title: 'Marketing Guide',
    description:
      'Unlock the secrets to digital success with insights from Ignition NBS Ltd, renowned marketing specialists for insurance brokers. Explore a wealth of guides designed to enhance your online presence and maximize your marketing efforts. Get ready to elevate your brand and attract new clients!',
    backgroundColour: '#000303',
    textColour: '#f0ff93',
    backTextColour: '#f0ff93',
    emptyCardBackground: '#e6d8ea',
    defaults: {
      open: false,
    },
  },
  {
    type: 'course',
    id: 'd1c90dbc-6e2e-4e77-90c3-319a5bc220a6',
    slug: 'working-well-toolkit',
    active: true,
    title: 'Working Well Toolkit',
    description:
      'Equip your team for success in today’s evolving landscape. This toolkit offers practical resources for broker managers and leaders tackling the unique challenges of remote work. Access templates, guidance, and strategies on everything from effective onboarding to managing burnout and productivity.',
    backgroundColour: '#000303',
    textColour: '#f0ff93',
    backTextColour: '#f0ff93',
    emptyCardBackground: '#e6d8ea',
    defaults: {
      open: false,
    },
  },
];

export const Courses = {
  courses,
};
