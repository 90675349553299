import Vue from 'vue';
import VueRouter from 'vue-router';

import { AppConfig } from '@/config/config';
import { TenantContent } from '@/content/tenants/tenants';
import Modules from '@/views/Module.vue';
import AccessCodeEntry from '@/content/tenants/ie-ni-shared/AccessCodeEntry.vue';
import AccessCodeEntryElevate from '@/content/tenants/ie-ni-shared/AccessCodeEntryElevate.vue';

for (var i = 0; i < TenantContent.tenants.length; i++) {
  if (TenantContent.tenants[i].id == AppConfig.tenant.id) {
    var tenant = TenantContent.tenants[i];
  }
}

Vue.use(VueRouter);

let routes = [];


// Brokerwise specific routes
if (tenant.slug == 'brokerwise-pro') {
  routes.push.apply(routes, [
    // {
    //   path: '/design/test-card',
    //   name: 'Testcard',
    //   component: () => import(/* webpackChunkName: "info" */ '@/content/tenants/' + tenant.slug + '/design/TestCard.vue')
    // },
  ]);
}

// Broker Elevate specific routes
if (tenant.slug == 'brokerelevate-co-uk') {
  routes.push.apply(routes, [
    {
      path: '/',
      name: 'Home',
      // component: () =>
      //   import(/* webpackChunkName: "home" */ '@/content/tenants/' + tenant.slug + '/Home.vue'),
      component: () =>
        import(/* webpackChunkName: "info" */ '@/content/tenants/' + tenant.slug + '/Moved.vue'),
    },
    {
      path: '/about',
      name: 'About',
      // component: () =>
      //   import(/* webpackChunkName: "info" */ '@/content/tenants/' + tenant.slug + '/About.vue'),
      component: () =>
        import(/* webpackChunkName: "info" */ '@/content/tenants/' + tenant.slug + '/Moved.vue'),
    },
    {
      path: '/proposition',
      name: 'Proposition',
      // component: () =>
      //   import(
      //     /* webpackChunkName: "info" */ '@/content/tenants/' + tenant.slug + '/Proposition.vue'
      //   ),
      component: () =>
        import(/* webpackChunkName: "info" */ '@/content/tenants/' + tenant.slug + '/Moved.vue'),
    },
    {
      path: '/brokerwise',
      name: 'BrokerwiseModules',
      component: () =>
        import(
          /* webpackChunkName: "brokerwise" */ '@/content/tenants/' +
            tenant.slug +
            '/Brokerwise.vue'
        ),
      // component: () =>
      //   import(
      //     /* webpackChunkName: "info" */ '@/content/tenants/' +
      //       tenant.slug +
      //       '/MovedBrokerwiseModules.vue'
      //   ),
    },
    {
      path: '/contact',
      name: 'Contact',
      // component: () =>
      //   import(/* webpackChunkName: "info" */ '@/content/tenants/' + tenant.slug + '/Contacts.vue'),
      component: () =>
        import(/* webpackChunkName: "info" */ '@/content/tenants/' + tenant.slug + '/Moved.vue'),
    },
    {
      path: '/modules/:moduleSlug?',
      name: 'Module',
      props: true,
      // component: () => import(/* webpackChunkName: "module" */ '../views/Module.vue'),
      component: () =>
        import(/* webpackChunkName: "info" */ '@/content/tenants/' + tenant.slug + '/Moved.vue'),
    },
    {
      path: '/deals',
      name: 'PropositionStatus',
      component: () =>
        import(
          /* webpackChunkName: "brokerwise" */ '@/content/features/' +
            tenant.slug +
            '/propositions/StatusDisplay.vue'
        ),
    },
    {
      path: '/update-deals',
      name: 'PropositionUpdate',
      component: () =>
        import(
          /* webpackChunkName: "brokerwise" */ '@/content/features/' +
            tenant.slug +
            '/propositions/StatusUpdate.vue'
        ),
    },
  ]);
}

// Brokerwise IE specific routes
// if (tenant.slug == 'ie-brokerwise-pro') {
//   routes.push.apply(routes,
//     [
//       {
//         path: '/access',
//         name: 'AccessCode',
//         component: () => import(/* webpackChunkName: "brokerwise" */ '@/content/tenants/' + tenant.slug + '/AccessCode.vue')
//       },
//     ],
//   )
// }

// Brokerwise IE / NI specific routes
if (tenant.slug == 'ie-brokerwise-pro' || tenant.slug == 'ni-brokerwise-pro') {
  routes.push.apply(routes, [
    {
      // can't be lazy loaded because we need the route to set on mount
      path: '/access-code-entry',
      name: 'AccessCodeEntry',
      component: AccessCodeEntry,
    },
    {
      // can't be lazy loaded because we need the route to set on mount
      path: '/elevate-access',
      name: 'AccessCodeEntryElevate',
      component: AccessCodeEntryElevate,
    },
    {
      path: '/spotlight-articles',
      name: 'SpotlightArticles',
      component: () =>
        import(
          /* webpackChunkName: "brokerwise-ie" */ '@/content/tenants/ie-ni-shared/SpotlightArticles.vue'
        ),
    },
  ]);
}


// Brokerwise PP specific routes
if (tenant.slug == 'pp-brokerwise-pro') {
  routes.push.apply(routes, [
    // {
    //   path: '/design/test-card',
    //   name: 'Testcard',
    //   component: () => import(/* webpackChunkName: "info" */ '@/content/tenants/' + tenant.slug + '/design/TestCard.vue')
    // },
  ]);
}

// Common routes
routes.push.apply(routes, [
  {
    path: '/',
    name: 'Home',
    component: () =>
      import(/* webpackChunkName: "home" */ '@/content/tenants/' + tenant.slug + '/Home.vue'),
  },
  {
    path: '/r/:searchTerm?',
    name: 'Results',
    props: true,
    component: () =>
      import(/* webpackChunkName: "results" */ '@/content/tenants/' + tenant.slug + '/Results.vue'),
  },
  {
    path: '/modules/:moduleSlug?',
    name: 'Module',
    props: true,
    component: () => import(/* webpackChunkName: "module" */ '../views/Module.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "auth" */ '../views/auth/Login.vue'),
  },
  {
    path: '/logout',
    name: 'Logout',
    component: () => import(/* webpackChunkName: "auth" */ '../views/auth/Logout.vue'),
  },
  {
    path: '/register/:invitationId?/:hash?',
    name: 'Register',
    component: () => import(/* webpackChunkName: "auth" */ '../views/auth/Register.vue'),
  },
  {
    path: '/reset-password/:token?',
    name: 'ResetPassword',
    component: () => import(/* webpackChunkName: "auth" */ '../views/auth/ResetPassword.vue'),
  },
  {
    path: '/error/401',
    name: 'FourOhOne',
    component: () => import(/* webpackChunkName: "errors" */ '../views/errors/401.vue'),
  },
  {
    path: '/error/403',
    name: 'FourOhThree',
    component: () => import(/* webpackChunkName: "errors" */ '../views/errors/403.vue'),
  },
  {
    path: '/error/404/',
    name: 'FourOhFour',
    component: () => import(/* webpackChunkName: "errors" */ '../views/errors/404.vue'),
  },
  {
    path: '/error/unknown',
    name: 'untrapped',
    component: () => import(/* webpackChunkName: "errors" */ '../views/errors/Untrapped.vue'),
  },
  {
    path: '/about',
    name: 'About',
    component: () =>
      import(/* webpackChunkName: "info" */ '@/content/tenants/' + tenant.slug + '/About.vue'),
  },
  {
    path: '/disclaimer',
    name: 'Disclaimer',
    component: () =>
      import(/* webpackChunkName: "info" */ '@/content/tenants/' + tenant.slug + '/Disclaimer.vue'),
  },
  {
    path: '/terms',
    name: 'Terms',
    component: () =>
      import(/* webpackChunkName: "info" */ '@/content/tenants/' + tenant.slug + '/Terms.vue'),
  },
  {
    path: '/newsletter',
    name: 'Newsletter',
    component: () =>
      import(
        /* webpackChunkName: "info" */ '@/content/tenants/' + tenant.slug + '/NewsletterSignup.vue'
      ),
  },
  // {
  //   path: '/list-all',
  //   name: 'ListAll',
  //   component: () =>
  //     import(/* webpackChunkName: "info" */ '@/content/tenants/' + tenant.slug + '/ListAll.vue'),
  // },
]);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
