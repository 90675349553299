<template>
  <div id="app">
    <app-brokerwise-pro v-if="this.$tenant.slug === 'brokerwise-pro'" />
    <app-brokerelevate-co-uk v-else-if="this.$tenant.slug === 'brokerelevate-co-uk'" />
    <app-ie-brokerwise-pro v-else-if="this.$tenant.slug === 'ie-brokerwise-pro'" />
    <app-ni-brokerwise-pro v-else-if="this.$tenant.slug === 'ni-brokerwise-pro'" />
    <app-pp-brokerwise-pro v-else-if="this.$tenant.slug === 'pp-brokerwise-pro'" />
  </div>
</template>

<script>
import { MetricsMixin } from '@/mixins/metrics.mixin';
import AppBrokerwisePro from '@/content/tenants/brokerwise-pro/App';
import AppBrokerelevateCoUk from '@/content/tenants/brokerelevate-co-uk/App';
import AppIeBrokerwisePro from '@/content/tenants/ie-brokerwise-pro/App';
import AppNiBrokerwisePro from '@/content/tenants/ni-brokerwise-pro/App';
import AppPpBrokerwisePro from '@/content/tenants/pp-brokerwise-pro/App';

export default {
  mixins: [MetricsMixin],
  components: {
    AppBrokerwisePro,
    AppBrokerelevateCoUk,
    AppIeBrokerwisePro,
    AppNiBrokerwisePro,
    AppPpBrokerwisePro
  },
  created() {
    this.$user.fetchCollection();
  },
  watch: {
    $route(to, from) {
      // Some full screen iframes hide the page scrollbar to make the page
      // less confusing for the users, this puts them back!
      document.body.classList.remove('hide-scrollbar');

      this.metricWrite('navigation', {
        event: 'route-change',
        toName: to.name,
        toPath: to.path,
        fromName: from.name,
        fromPath: from.path,
      });
    },
  },
};
</script>


<style>
.temporary {
  background: repeating-linear-gradient(45deg, #ffc4c4, #ffc4c4 10px, #ffffff 10px, #ffffff 20px);
  padding: 10px;
  font-weight: 800;
}
</style>