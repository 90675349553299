const module = [
  {
    id: 'd33c06b9-0ba6-437b-8424-db2ef31d0387',
    slug: 'tips-and-guidance-for-monitoring-productivity',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: 'd33c06b9-0ba6-437b-8424-db2ef31d0387|d1c90dbc-6e2e-4e77-90c3-319a5bc220a6',
    template: 'pdf-template',
    title: 'Tips And Guidance For Monitoring Productivity',
    description:
      'This article will provide tips and guidance on monitoring productivity and dealing with performance challenges remotely.',
    completedDescription: 'TODO',
    supplier: 'Ignition NBS',
    categoryId: 'd1c90dbc-6e2e-4e77-90c3-319a5bc220a6',
    questionnaireId: '',
    contents: {
      id: '2f6ee6d9-31ca-4c7b-b34d-47238e8a9da4',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'One of the key challenges faced by line managers with remote teams is the ability to know exactly what your team are doing throughout the day. This article will provide tips and guidance on monitoring productivity and dealing with performance challenges remotely.',
      heroImage: false,
      media: [
        {
          type: 'document',
          subType: 'pdf',
          title: 'Tips And Guidance For Monitoring Productivity',
          button: '',
          fileSize: 0,
          url: 'tips-and-guidance-for-monitoring-productivity.pdf',
          caption: {
            title: '',
            description:
              ''
          }
        },
        {
          type: 'file',
          subType: 'pdf',
          title: 'Tips And Guidance For Monitoring Productivity',
          button: 'Download File',
          fileSize: '175112 ',
          url: 'tips-and-guidance-for-monitoring-productivity.pdf',
          caption: {
            title: '',
            description: ''
          },
          axaUrl: ''
        }
      ]
    },
    progress: {
      value: 1,
      maximum: 1
    },
    statistics: {
      level: 0,
      timeEstimate: '30'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['management','leadership']
  },
];

export const TipsAndGuidanceForMonitoringProductivity = {
  module
};