const module = [
  {
    id: '8d194232-6656-4ccb-9558-340e4854d9dd',
    slug: 'best-practice-for-onboarding-remotely-and-managing-new-starters',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: '8d194232-6656-4ccb-9558-340e4854d9dd|d1c90dbc-6e2e-4e77-90c3-319a5bc220a6',
    template: 'pdf-template',
    title: 'Best Practice for Onboarding Remotely and Managing New Starters',
    description:
      'Research shows new employees decide how long they want to work for a company within the first 3-6 months, which means that the onboarding experience is key.',
    completedDescription: 'TODO',
    supplier: 'Ignition NBS',
    categoryId: 'd1c90dbc-6e2e-4e77-90c3-319a5bc220a6',
    questionnaireId: '',
    contents: {
      id: '8c875e24-ee4d-4308-9280-75f642bf26ff',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'Research shows that new employees decide how long they want to work for a company within the first 3-6 months, which means that a positive onboarding experience is key.',
      heroImage: false,
      media: [
        {
          type: 'document',
          subType: 'pdf',
          title: 'Best Practice for Onboarding Remotely and Managing New Starters',
          button: '',
          fileSize: 0,
          url: 'best-practice-for-onboarding-remotely-and-managing-new-starters.pdf',
          caption: {
            title: '',
            description:
              ''
          }
        },
        {
          type: 'file',
          subType: 'pdf',
          title: 'Best Practice for Onboarding Remotely and Managing New Starters',
          button: 'Download File',
          fileSize: '280039',
          url: 'best-practice-for-onboarding-remotely-and-managing-new-starters.pdf',
          caption: {
            title: '',
            description: ''
          },
          axaUrl: ''
        }
      ]
    },
    progress: {
      value: 1,
      maximum: 1
    },
    statistics: {
      level: 0,
      timeEstimate: '30'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['management','leadership','wellbeing']
  },
];

export const BestPracticeForOnboardingRemotelyAndManagingNewStarters = {
  module
};