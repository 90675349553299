const module = [
  {
    id: '83d3d287-c232-474d-baab-0d4321faf221',
    slug: 'burnout-toolkit',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: '83d3d287-c232-474d-baab-0d4321faf221|d1c90dbc-6e2e-4e77-90c3-319a5bc220a6',
    template: 'pdf-template',
    title: 'Burnout Toolkit',
    description:
      'This article provides you with the knowledge required to recognise the warning signs of burnout and how to support your team.',
    completedDescription: 'TODO',
    supplier: 'Ignition NBS',
    categoryId: 'd1c90dbc-6e2e-4e77-90c3-319a5bc220a6',
    questionnaireId: '',
    contents: {
      id: '88c4a5f6-d7ec-4ada-88b3-f91712bf3694',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'A guide for line managers to help you recognise the warning signs of burnout and support your team. Resources include tips to guide you through approaching challenging conversations, implementing support measures and managing the issue with your wider team.',
      heroImage: false,
      media: [
        {
          type: 'document',
          subType: 'pdf',
          title: 'Burnout Toolkit',
          button: '',
          fileSize: 0,
          url: 'burnout-toolkit.pdf',
          caption: {
            title: '',
            description:
              ''
          }
        },
        {
          type: 'file',
          subType: 'pdf',
          title: 'Burnout Toolkit',
          button: 'Download File',
          fileSize: '151846',
          url: 'burnout-toolkit.pdf',
          caption: {
            title: '',
            description: ''
          },
          axaUrl: ''
        }
      ]
    },
    progress: {
      value: 1,
      maximum: 1
    },
    statistics: {
      level: 0,
      timeEstimate: '30'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['management','leadership','wellbeing']
  },
];

export const BurnoutToolkit = {
  module
};